import Stack from "@mui/material/Stack/Stack";
import ToggleButton from "@mui/material/ToggleButton/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup/ToggleButtonGroup";
import React, { useContext } from "react";
import LaptopIcon from "@mui/icons-material/Laptop";
import TabletIcon from "@mui/icons-material/Tablet";

import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import {
  CheckAccessFrameContext,
  DeviceMode,
} from "providers/CheckAccessFrame/CheckAccessFrame";
import PathSearchBar from "./PathSearchBar";
import Box from "@mui/material/Box/Box";
import { useCurrentProjectPage } from "providers/CurrentFrameProjectPage/CurrentFrameProjectPage";

export interface TopToolbarProps {}

export const TOP_TOOLBAR_HEIGHT = 60;

const TopToolbar: React.FC<TopToolbarProps> = () => {
  const { deviceMode, changeDeviceMode } = useContext(CheckAccessFrameContext)!;
  const currentProjectPage = useCurrentProjectPage();

  const handleChangeDeviceMode = (
    _: React.MouseEvent<HTMLElement>,
    newDeviceMode: DeviceMode
  ) => {
    if (newDeviceMode !== null) {
      changeDeviceMode(newDeviceMode);
    }
  };

  return (
    <Stack
      direction="row"
      sx={{
        padding: "10px",
        backgroundColor: "secondary.main",
        maxHeight: `${TOP_TOOLBAR_HEIGHT}px`,
      }}
    >
      <ToggleButtonGroup
        value={deviceMode}
        color="primary"
        exclusive
        onChange={handleChangeDeviceMode}
        aria-label="device"
        size="small"
      >
        <ToggleButton value={DeviceMode.Laptop} aria-label="laptop">
          <LaptopIcon />
        </ToggleButton>
        <ToggleButton value={DeviceMode.Tablet} aria-label="tablet">
          <TabletIcon />
        </ToggleButton>
        <ToggleButton value={DeviceMode.Phone} aria-label="phone">
          <PhoneAndroidIcon />
        </ToggleButton>
      </ToggleButtonGroup>

      <Box sx={{ flexGrow: "1" }} />
      <PathSearchBar key={currentProjectPage.path} />
    </Stack>
  );
};

export default TopToolbar;
