import React, { useContext } from "react";
import useScaner from "hooks/useScaner";
import { CacheProvider } from "@emotion/react";
import { CheckAccessFrameContext } from "providers/CheckAccessFrame/CheckAccessFrame";
import HeadingMissingTitlesRetribution from "./HeadingMissingTitlesRetribution";
import { HeadingsRetributionProvider } from "./HeadingsRetributionProvider";
import HeadingOverlay from "./HeadingOverlay/HeadingOverlay";
import HeadingsTreeSidebarRetribution from "./SidebarRetribution/HeadingsTreeSidebarRetribution";
import MissingTitlesSidebarRetribution from "./SidebarRetribution/MissingTitlesSidebarRetribution";
import HeadingsDefnieAllTextInterventions from "./SidebarRetribution/HeadingsDefnieAllTextInterventions";

export interface HeadingsRetributionProps {}

const HeadingsRetribution: React.FC<HeadingsRetributionProps> = () => {
  const { shadowContainerStyleCache } = useContext(CheckAccessFrameContext)!;

  const { currentPageScan } = useScaner();
  const titlesScan = currentPageScan?.scan.informationStructuring.titles || [];

  return (
    <HeadingsRetributionProvider>
      <MissingTitlesSidebarRetribution />
      <HeadingsDefnieAllTextInterventions />
      <HeadingsTreeSidebarRetribution />

      <CacheProvider value={shadowContainerStyleCache}>
        {titlesScan.map((title, i) => (
          <HeadingOverlay key={i} scan={title} />
        ))}

        <HeadingMissingTitlesRetribution />
      </CacheProvider>
    </HeadingsRetributionProvider>
  );
};

export default HeadingsRetribution;
