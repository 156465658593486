import Box from "@mui/material/Box/Box";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import { useTheme } from "@mui/material/styles";
import RetributionButtonPopper from "components/check-access/scanReportRetribution/RetributionButtonPopper";
import { ICON_CUSTOM_STYLE } from "components/check-access/scanReportRetribution/RetributionIcon";
import RetributionNodeOverlay from "components/check-access/scanReportRetribution/RetributionNodeOverlay";
import RetributionTooltip from "components/check-access/scanReportRetribution/RetributionTooltip";
import useScaner from "hooks/useScaner";
import { useXPathFinder } from "providers/XPathFinder";
import React, { useMemo, useState } from "react";
import Alert from "@mui/material/Alert/Alert";
import Button from "@mui/material/Button/Button";
import { useHeadingsRetribution } from "./HeadingsRetributionProvider";
import { NodeInformationChips } from "components/check-access/scanReportRetribution/NodeInformationChips";

const RetributionCheckbox = React.forwardRef<
  HTMLDivElement,
  {
    isErrored: boolean;
    xPath: string;
    setMissingTitleIntervention: (xPath: string, isMissing: boolean) => void;
  }
>(({ isErrored, xPath, setMissingTitleIntervention }, ref) => {
  const { palette } = useTheme();

  const [isHoverIcon, setIsHoverIcon] = useState(false);
  const targetElement = useXPathFinder(xPath);
  if (!targetElement) return null;

  const color = isErrored ? "error" : "warning";

  return (
    <>
      <RetributionButtonPopper
        key={`missingTitlePopper-${xPath}`}
        xPath={xPath}
        placement={"bottom"}
        offset={[0, 4]}
      >
        <Box
          ref={ref}
          style={{
            ...ICON_CUSTOM_STYLE,
            backgroundColor: palette[color].main,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Checkbox
            onMouseEnter={() => setIsHoverIcon(true)}
            onMouseLeave={() => setIsHoverIcon(false)}
            checked={isErrored}
            onChange={() => setMissingTitleIntervention(xPath, !isErrored)}
            aria-label="Checkbox Missing Title"
            color={color}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 28,
                fill: palette.common.white,
              },
            }}
          />
        </Box>
      </RetributionButtonPopper>
      {isHoverIcon && !isErrored && (
        <RetributionNodeOverlay
          title={targetElement.tagName}
          anchorEl={targetElement}
          color={palette[color].main}
          borderSize={3}
        />
      )}
    </>
  );
});

const MissingTitleOverlayRetribution: React.FC<{
  xPath: string;
  setMissingTitleIntervention: (xPath: string, isMissing: boolean) => void;
}> = ({ xPath, setMissingTitleIntervention }) => {
  const { palette } = useTheme();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const element = useXPathFinder(xPath);
  if (!element) return null;

  return (
    <RetributionTooltip
      open={isTooltipOpen}
      title={"Cette balise devrait être un titre"}
      anchorEl={element}
      content={
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: 1,
            flexDirection: "column",
          }}
        >
          <Alert severity="error">
            Vous devez modifier cette balise sois en changant son tag par{" "}
            <code>{"<hx>"}</code> (“x” désignant une valeur numérique comprise
            entre 1 et 6) <br /> Ou en rajoutant l'attribut{" "}
            <code>role="heading"</code> avec un attribut <code>aria-level</code>
          </Alert>

          <NodeInformationChips
            chips={[
              {
                label: "Nom de balise",
                content: element.tagName,
              },
              {
                label: "WAI-ARIA Rôle",
                content: element.role || "Aucun",
              },
            ]}
            textAlign="center"
          />

          <Button
            onClick={() => setMissingTitleIntervention(xPath, false)}
            variant="contained"
            loadingPosition="end"
            color="error"
          >
            Annuler l'intervention
          </Button>
        </Box>
      }
      onClickAway={() => setIsTooltipOpen(false)}
      referenceLink={
        "https://accessibilite.numerique.gouv.fr/methode/criteres-et-tests/#9.1.3"
      }
      color={palette.error.main}
    >
      <Box>
        <RetributionNodeOverlay
          title={element.tagName}
          retrievalId={`missingTitleOverlay-${xPath}`}
          anchorEl={element}
          onClick={() => setIsTooltipOpen(!isTooltipOpen)}
          color={palette.error.main}
          borderSize={3}
        />
      </Box>
    </RetributionTooltip>
  );
};

const HeadingMissingTitlesRetribution: React.FC = () => {
  const { currentPageScan } = useScaner();
  const {
    isMissingTitlesEditMode,
    missingTitlesInterventions,
    setMissingTitleIntervention,
  } = useHeadingsRetribution();

  const textsToEdit = useMemo(() => {
    if (!currentPageScan || !isMissingTitlesEditMode) return [];

    const scanPaths = currentPageScan.scan.informationStructuring.titles.map(
      (it) => it.xPath
    );

    return currentPageScan.scan.texts.textScans.filter(
      (text) => !scanPaths.includes(text.xPath)
    );
  }, [currentPageScan, isMissingTitlesEditMode]);

  return (
    <React.Fragment>
      {textsToEdit.map((text) => {
        const isErrored = missingTitlesInterventions.has(text.xPath);
        return (
          <RetributionCheckbox
            key={`missingTitle-${text.xPath}`}
            isErrored={isErrored}
            xPath={text.xPath}
            setMissingTitleIntervention={setMissingTitleIntervention}
          />
        );
      })}

      {[...missingTitlesInterventions].map((xPath) => (
        <MissingTitleOverlayRetribution
          key={`missingTitleError-${xPath}`}
          xPath={xPath}
          setMissingTitleIntervention={setMissingTitleIntervention}
        />
      ))}
    </React.Fragment>
  );
};

export default HeadingMissingTitlesRetribution;
