import { useTheme } from "@mui/material";
import Box from "@mui/material/Box/Box";
import React, { useContext, useMemo } from "react";
import { ReportContext, TargetedNonCompliantErrors } from "providers/Report";
import { UnionTypeArray } from "utils/tsUtils";
import {
  ImageCriteria,
  InterventionRequirement,
  number_to_image_criteria,
  Topic,
} from "@ca/report";
import { ReactComponent as ImageIcon } from "../../../../assets/icons/icon-image.svg";
import { ReactComponent as DecorativeImage } from "../../../../assets/icons/icon-decorative-image.svg";
import { ReactComponent as InformativeImage } from "../../../../assets/icons/icon-informative-image.svg";
import { ReactComponent as TextIcon } from "../../../../assets/icons/icon-image-text.svg";
import {
  AllPicturesNeededInterventionTypes,
  ALL_PICTURES_NEEDED_INTERVENTION_TYPES,
} from "definitions/topic_details";
import SidebarTopicHeader from "./SidebarTopicHeader";
import AccordionPictoLink from "../accordion/AccordionPictoLink";
import RetributionIcon from "components/check-access/scanReportRetribution/RetributionIcon";
import { XPathFinderContext } from "providers/XPathFinder";

export interface PicturesSidebarTopicDetailsProps {}

const PicturesSidebarTopicDetails: React.FC<
  PicturesSidebarTopicDetailsProps
> = () => {
  const { palette } = useTheme();
  const { pageReport, nonCompliantCriterias, needInterventionsCriterias } =
    useContext(ReportContext)!;
  const { scrollPictoIntoView } = useContext(XPathFinderContext)!;

  const picturesErrors: Record<ImageCriteria, TargetedNonCompliantErrors[]> =
    useMemo(() => {
      return Object.fromEntries(
        nonCompliantCriterias
          .filter((criteria) => criteria.topic === "images")
          .map((criteria) => [
            number_to_image_criteria(criteria.number),
            criteria.state.nonCompliant,
          ])
          .concat(
            needInterventionsCriterias
              .filter((criteria) => criteria.topic === "images")
              .map((criteria) => [
                number_to_image_criteria(criteria.number),
                criteria.state.needIntervention,
              ])
          )
      );
    }, [needInterventionsCriterias, nonCompliantCriterias]);

  const picutresNeededInterventions: UnionTypeArray<
    InterventionRequirement,
    AllPicturesNeededInterventionTypes
  > = useMemo(() => {
    return Object.fromEntries(
      ALL_PICTURES_NEEDED_INTERVENTION_TYPES.map((type) => [
        type,
        pageReport?.neededInterventions.filter(
          (inter) => inter.type === type
        ) || [],
      ])
    ) as UnionTypeArray<
      InterventionRequirement,
      AllPicturesNeededInterventionTypes
    >;
  }, [pageReport?.neededInterventions]);

  const picturesUsefulnessPictoWarnings = useMemo(() => {
    return picutresNeededInterventions.pictureUsefulness.map((inter, i) => (
      <RetributionIcon
        onClick={() => scrollPictoIntoView("pictureUsefulness", inter.xPath)}
        Icon={(props) => <ImageIcon {...props} stroke={palette.common.white} />}
        key={i}
        color={palette.warning.main}
      />
    ));
  }, [
    palette,
    picutresNeededInterventions.pictureUsefulness,
    scrollPictoIntoView,
  ]);

  const topic: Topic = "images";

  return (
    <Box sx={{ width: "100%" }}>
      <SidebarTopicHeader topic="images" />
      <Box
        sx={{
          margin: "25px 8px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Box>
          <AccordionPictoLink
            title="1.1 Image informative mal implémentée"
            topic={topic}
            criteriaNumber={1}
          >
            {picturesUsefulnessPictoWarnings}
            {picturesErrors.alternativeImplemented?.map((error, i) => (
              <RetributionIcon
                onClick={() =>
                  scrollPictoIntoView("pictureUsefulness", error.x_path)
                }
                Icon={(props) => <InformativeImage {...props} fill="white" />}
                key={i}
                color={palette.error.main}
              />
            ))}
          </AccordionPictoLink>

          <AccordionPictoLink
            title="1.2 Image décorative mal implémentée"
            topic={topic}
            criteriaNumber={2}
          >
            {picturesUsefulnessPictoWarnings}
            {picturesErrors.decorativesIgnored?.map((error, i) => (
              <RetributionIcon
                onClick={() =>
                  scrollPictoIntoView("pictureUsefulness", error.x_path)
                }
                Icon={(props) => <DecorativeImage {...props} fill="white" />}
                key={i}
                color={palette.error.main}
              />
            ))}
          </AccordionPictoLink>

          <AccordionPictoLink
            title="1.3 Mauvaise alternative textuel"
            topic={topic}
            criteriaNumber={3}
          >
            {picturesUsefulnessPictoWarnings}
            {picutresNeededInterventions.pictureAltTextRelevance?.map(
              (it, i) => (
                <RetributionIcon
                  onClick={() =>
                    scrollPictoIntoView("pictureUsefulness", it.xPath)
                  }
                  Icon={(props) => <InformativeImage {...props} fill="white" />}
                  key={i}
                  color={palette.warning.main}
                />
              )
            )}
            {picturesErrors.revelantAlternative?.map((error, i) => (
              <RetributionIcon
                onClick={() =>
                  scrollPictoIntoView("pictureUsefulness", error.x_path)
                }
                Icon={(props) => <InformativeImage {...props} fill="white" />}
                key={i}
                color={palette.error.main}
              />
            ))}
          </AccordionPictoLink>

          <AccordionPictoLink
            title="1.4 Mauvaise alternative textuel au Captcha"
            topic={topic}
            criteriaNumber={4}
          >
            {picturesErrors.captchaAlternative?.map((error, i) => (
              <RetributionIcon
                onClick={() => scrollPictoIntoView("frameTitle", error.x_path)}
                Icon={(props) => <InformativeImage {...props} fill="white" />}
                key={i}
                color={palette.error.main}
              />
            ))}
          </AccordionPictoLink>

          <AccordionPictoLink
            title="1.5 Pas de solution alternative au captcha"
            topic={topic}
            criteriaNumber={4}
          >
            {picturesErrors.captchaReplacement?.map((error, i) => (
              <RetributionIcon
                onClick={() => scrollPictoIntoView("frameTitle", error.x_path)}
                Icon={(props) => <InformativeImage {...props} fill="white" />}
                key={i}
                color={palette.error.main}
              />
            ))}
          </AccordionPictoLink>

          <AccordionPictoLink
            title="1.8 Pas de remplacement pour les images avec du texte"
            topic={topic}
            criteriaNumber={8}
          >
            {picturesUsefulnessPictoWarnings}
            {picturesErrors.textPicture?.map((inter, i) => (
              <RetributionIcon
                onClick={() => scrollPictoIntoView("textPicture", inter.x_path)}
                Icon={(props) => <TextIcon {...props} fill="white" />}
                key={i}
                color={palette.error.main}
              />
            ))}
          </AccordionPictoLink>
        </Box>
      </Box>
    </Box>
  );
};

export default PicturesSidebarTopicDetails;
