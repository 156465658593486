import Box from "@mui/material/Box/Box";
import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import CriteriaAccordion from "./CriteriaAccordion";
import { CRITERIA_STATE_DETAILS } from "definitions/criteria_state_details";
import { getCriteriaState, ReportContext } from "providers/Report";
import { Topic } from "@ca/report";

export type AccordionPictoLinkProps = {
  title: string;
  topic: Topic;
  criteriaNumber: number;

  children: React.ReactNode;
};

const AccordionPictoLink: React.FC<AccordionPictoLinkProps> = ({
  title,
  topic,
  criteriaNumber,
  children,
}) => {
  const { pageReport } = useContext(ReportContext)!;
  const { palette } = useTheme();

  const criteria = pageReport?.criterias.find(
    (criteria) => criteria.topic === topic && criteria.number === criteriaNumber
  );

  if (!criteria) return null;

  const criteriaState = getCriteriaState(criteria);
  const details = CRITERIA_STATE_DETAILS[criteriaState];

  return (
    <CriteriaAccordion
      title={title}
      Icon={<details.Icon stroke={palette[details.paletteTheme].main} />}
      type={details.paletteTheme}
    >
      <Box sx={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
        {children}
      </Box>
    </CriteriaAccordion>
  );
};

export default AccordionPictoLink;
