import { Infos, Intervention } from "@ca/report";
import useCurrentProject from "hooks/useCurrentProject";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { logError } from "utils/SentryUtils";
import { FilterByProperty } from "utils/tsUtils";
import { OrganizationsContext } from "./OrganizationsProvider";

type PageId = string;

export type TargetedInterventions = FilterByProperty<Intervention, "x_path">;

const safeInfosParsing = (infos: Infos): Infos => {
  if (Array.isArray(infos?.["interventions"])) {
    return infos;
  } else {
    console.error("bad infos file found");

    return {
      interventions: [],
    };
  }
};

type InterventionsContextProps = {
  getPageInfos: (pageId: PageId) => Infos;
  replaceTargetedIntervention: (
    intervention: TargetedInterventions,
    pageId: PageId
  ) => void;
  addIntervention: (
    intervention: Intervention,
    pageId: PageId,
    toRemoveCallback: (intervention: Intervention) => boolean
  ) => void;
  removeInterventions: (
    pageId: PageId,
    toRemoveCallback: (intervention: Intervention) => boolean
  ) => void;
  bulkAddInterventions: (
    interventions: Intervention[],
    pageId: PageId,
    toRemoveCallback?: (intervention: Intervention) => boolean
  ) => void;
  findInterventionByPathAndType: <Type extends TargetedInterventions["type"]>(
    pageId: string,
    xPath: string,
    type: Type
  ) => Extract<TargetedInterventions, { type: Type }> | undefined;
};

const InterventionsContext = createContext<InterventionsContextProps | null>(
  null
);

const InterventionProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const project = useCurrentProject();
  const { updateProjectPage } = useContext(OrganizationsContext)!;

  // We duplicate SSOT here to escape update interventions ping
  const [infos, setInfos] = useState<Record<PageId, Infos>>(
    {} as Record<PageId, Infos>
  );

  useEffect(() => {
    setInfos(() =>
      project.pages.reduce(
        (acc, page) => {
          acc[page.id] = safeInfosParsing(page.infos);
          return acc;
        },
        {} as Record<PageId, Infos>
      )
    );
  }, [project.pages]);

  const bulkAddInterventions = useCallback(
    (
      interventions: Intervention[],
      pageId: PageId,
      toRemoveCallback?: (intervention: Intervention) => boolean
    ) => {
      setInfos((prev) => {
        const filtredInterventions = toRemoveCallback
          ? prev[pageId].interventions.filter((el) => !toRemoveCallback(el))
          : prev[pageId].interventions;

        prev[pageId].interventions = [
          ...filtredInterventions,
          ...interventions,
        ];
        prev[pageId] = { ...prev[pageId] };

        updateProjectPage({
          project_id: project.id,
          id: pageId,
          infos: prev[pageId],
        });

        return { ...prev };
      });
    },
    [project.id, updateProjectPage]
  );

  const replaceTargetedIntervention = useCallback(
    (intervention: TargetedInterventions, pageId: PageId) => {
      setInfos((prev) => {
        prev[pageId].interventions = prev[pageId].interventions.filter(
          (i) =>
            i.type !== intervention.type || i.x_path !== intervention.x_path
        );
        prev[pageId].interventions.push(intervention);
        prev[pageId] = { ...prev[pageId] };

        updateProjectPage({
          project_id: project.id,
          id: pageId,
          infos: prev[pageId],
        });

        return { ...prev };
      });
    },
    [project.id, updateProjectPage]
  );

  const addIntervention = useCallback(
    (
      intervention: Intervention,
      pageId: PageId,
      toRemoveCallback: (intervention: Intervention) => boolean
    ) => {
      bulkAddInterventions([intervention], pageId, toRemoveCallback);
    },
    [bulkAddInterventions]
  );

  const removeInterventions = useCallback(
    (
      pageId: PageId,
      toRemoveCallback: (intervention: Intervention) => boolean
    ) => {
      setInfos((prev) => {
        const filtredInterventions = prev[pageId].interventions.filter(
          (el) => !toRemoveCallback(el)
        );

        prev[pageId].interventions = [...filtredInterventions];
        prev[pageId] = { ...prev[pageId] };

        updateProjectPage({
          project_id: project.id,
          id: pageId,
          infos: prev[pageId],
        });

        return { ...prev };
      });
    },
    [project.id, updateProjectPage]
  );

  const findInterventionByPathAndType = useCallback(
    <Type extends TargetedInterventions["type"]>(
      pageId: string,
      xPath: string,
      type: Type
    ): Extract<TargetedInterventions, { type: Type }> | undefined => {
      const pageInfos = infos[pageId];

      if (!pageInfos) {
        logError(`Page with id ${pageId} not found`);
        return undefined;
      }

      return pageInfos.interventions.find(
        (intervention) =>
          "x_path" in intervention &&
          intervention.x_path === xPath &&
          intervention.type === type
      ) as Extract<TargetedInterventions, { type: Type }> | undefined;
    },
    [infos]
  );

  const getPageInfos = useCallback(
    (pageId: string) => {
      return safeInfosParsing(infos[pageId]);
    },
    [infos]
  );

  return (
    <InterventionsContext.Provider
      value={{
        getPageInfos,
        addIntervention,
        removeInterventions,
        replaceTargetedIntervention,
        bulkAddInterventions,
        findInterventionByPathAndType,
      }}
    >
      {children}
    </InterventionsContext.Provider>
  );
};

export { InterventionsContext, InterventionProvider };
