import { useCallback, useContext, useEffect, useRef } from "react";
import { CheckAccessFrameContext } from "../CheckAccessFrame/CheckAccessFrame";

const CHECK_ACCESS_PROXY_URL = process.env.REACT_APP_CHECK_ACCESS_PROXY_URL!;

const usePageSwitchListner = (
  updateCurrentPageRef: React.RefObject<(url: string | URL) => Promise<void>>
) => {
  const { frame } = useContext(CheckAccessFrameContext)!;
  const oldFrameHref = useRef<string | null>(null);

  const overrideHistoryState = useCallback(
    (frameHistory: History) => {
      frameHistory.pushState = (data, unused, url) => {
        console.log("Push state event:", data, unused, url, data.url);
        const usedUrl = data.url || url;
        if (usedUrl) {
          updateCurrentPageRef.current?.(usedUrl);
        }

        throw new Error("Canceled push state event");
      };
    },
    [updateCurrentPageRef]
  );

  const checkLocationHrefChanged = useCallback(() => {
    // This function is our last guard to detect an URL change

    if (!frame?.contentWindow) return;

    let newHref: string | null = null;
    try {
      newHref = frame.contentWindow.location.href.replace(
        CHECK_ACCESS_PROXY_URL,
        ""
      );
    } catch (error) {
      console.error("Could not access location href", error);
      return;
    }

    if (newHref && oldFrameHref.current !== newHref) {
      console.log(
        "Location changed old: ",
        oldFrameHref.current,
        "new: ",
        newHref
      );
      oldFrameHref.current = newHref;
      updateCurrentPageRef.current?.(newHref);
    }
  }, [frame, updateCurrentPageRef]);

  const addClickListener = useCallback(
    (link: HTMLAnchorElement) => {
      link.addEventListener("click", (evt: MouseEvent) => {
        console.log("Link clicked: ", link, "href: ", link.href);
        if (link.href.trim() === "") return;

        evt.preventDefault();
        evt.stopPropagation();

        updateCurrentPageRef.current?.(link.href);

        return false;
      });

      link.setAttribute("data-ca-already-listened", "true");
    },
    [updateCurrentPageRef]
  );

  const setupLinksObserver = useCallback(
    (contentDocument: Document) => {
      const observer = new MutationObserver((mutations) => {
        mutations
          .flatMap((mutation) => Array.from(mutation.addedNodes))
          .filter(
            (node): node is HTMLElement => node.nodeType === Node.ELEMENT_NODE
          )
          .flatMap((node) => Array.from(node.getElementsByTagName("a")))
          .filter(
            (node) => node.getAttribute("data-ca-already-listened") !== "true"
          )
          .forEach(addClickListener);

        // checkLocationHrefChanged();
      });

      observer.observe(contentDocument, {
        childList: true,
        subtree: true,
      });

      Array.from(contentDocument.getElementsByTagName("a"))
        .filter(
          (link) => link.getAttribute("data-ca-already-listened") !== "true"
        )
        .forEach(addClickListener);

      return observer;
    },
    [addClickListener]
  );

  useEffect(() => {
    console.log(
      "Check access frame provider mounted",
      frame,
      frame?.contentWindow
    );

    if (!frame?.contentWindow) return;

    // checkLocationHrefChanged();
    overrideHistoryState(frame.contentWindow.history);

    try {
      console.log("history state:", frame.contentWindow.history.state);
    } catch {
      console.error("Could not access history state");
      return;
    }

    if (!frame.contentDocument) return;

    const observer = setupLinksObserver(frame.contentDocument);

    return () => {
      observer.disconnect();
    };
  }, [
    addClickListener,
    checkLocationHrefChanged,
    frame,
    frame?.contentDocument,
    overrideHistoryState,
    setupLinksObserver,
  ]);
};

export default usePageSwitchListner;
