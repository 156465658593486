import Box from "@mui/material/Box/Box";
import React, { useContext } from "react";
import {
  getCriteriaState,
  NonCompliantCriteria,
  ReportContext,
  TargetedNonCompliantErrors,
} from "providers/Report";
import SidebarTopicHeader from "./SidebarTopicHeader";
import { ReactComponent as ContrastIcon } from "../../../../assets/icons/icon-contrast.svg";
import AccordionPictoLink from "../accordion/AccordionPictoLink";
import RetributionIcon from "components/check-access/scanReportRetribution/RetributionIcon";
import { XPathFinderContext } from "providers/XPathFinder";
import { useTheme } from "@mui/material/styles";

export interface MandatoryElementsSidebarTopicDetailsProps {}

const ColorsSidebarTopicDetails: React.FC<
  MandatoryElementsSidebarTopicDetailsProps
> = () => {
  const { pageReport } = useContext(ReportContext)!;
  const { scrollPictoIntoView } = useContext(XPathFinderContext)!;
  const { palette } = useTheme();

  const criteria = pageReport?.criterias.find(
    (criteria) => criteria.topic === "colors" && criteria.number === 2
  );

  if (!criteria) return null;

  const criteriaState = getCriteriaState(criteria);

  const errors =
    criteriaState === "nonCompliant"
      ? (criteria as NonCompliantCriteria).state.nonCompliant
      : [];

  return (
    <Box sx={{ width: "100%" }}>
      <SidebarTopicHeader topic="colors" titleFontSize="1.5rem" />
      <Box
        sx={{
          margin: "25px 8px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Box>
          <AccordionPictoLink
            title="3.2 Contraste du texte"
            topic="colors"
            criteriaNumber={2}
          >
            {errors.map((error, index) => (
              <RetributionIcon
                onClick={() =>
                  scrollPictoIntoView(
                    "textContrast",
                    (error as TargetedNonCompliantErrors).x_path
                  )
                }
                Icon={(props) => (
                  <ContrastIcon {...props} fill="white" stroke="white" />
                )}
                key={index}
                color={palette.error.main}
              />
            ))}
          </AccordionPictoLink>
        </Box>
      </Box>
    </Box>
  );
};

export default ColorsSidebarTopicDetails;
