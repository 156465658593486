import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useTreeViewApiRef } from "@mui/x-tree-view/hooks/useTreeViewApiRef";
import { ReportContext } from "providers/Report";
import useScaner from "hooks/useScaner";
import { InterventionsContext } from "providers/InterventionsProvider";
import {
  InformationStructuringTitlesIntervention,
  Intervention,
  InterventionRequirement,
  NonCompliantError,
  TitleScan,
} from "@ca/report";
import { RichTreeViewApiRef } from "@mui/x-tree-view/RichTreeView/RichTreeView.types";

export type MissingTitlesIntervention =
  Intervention.informationStructuringTitles &
    InformationStructuringTitlesIntervention.missingTitle;

export type HeadingTextRelevanceIntervention =
  Intervention.informationStructuringTitles &
    InformationStructuringTitlesIntervention.titleTextRelevance;

export type HeadingHierarchyRelevanceIntervention =
  Intervention.informationStructuringTitles &
    InformationStructuringTitlesIntervention.titleHierarchyRelevance;

const getContentInterventionKey = (text: string, xPath: string) =>
  `${text.trim()}-${xPath}`;

const getHierarchyInterventionKey = (level: number, xPath: string) =>
  `${level}-${xPath}`;

const useHeadingsInformationsMaps = () => {
  const { currentPageScan } = useScaner();
  const { allErrors, pageReport } = useContext(ReportContext)!;
  const { getPageInfos } = useContext(InterventionsContext)!;

  const hierarchyErrorsMap = useMemo(
    () =>
      new Map(
        allErrors
          .filter((e) => e.type === "notRelevantHeadingHierarchy")
          .map((e) => [e.x_path, e] as const)
      ),
    [allErrors]
  );

  const contentErrorsMap = useMemo(
    () =>
      new Map(
        allErrors
          .filter((e) => e.type === "notRelevantHeadingText")
          .map((e) => [e.x_path, e] as const)
      ),
    [allErrors]
  );

  const contentInterventionsMap = useMemo(() => {
    if (!currentPageScan)
      return new Map<string, HeadingTextRelevanceIntervention>();

    const interventions = getPageInfos(currentPageScan.page_id)
      .interventions.filter(
        (intervention) =>
          intervention.type === "informationStructuringTitles" &&
          intervention.titlesInterventionType === "titleTextRelevance"
      )
      .map(
        (it) => [getContentInterventionKey(it.text, it.x_path), it] as const
      );

    return new Map(interventions);
  }, [currentPageScan, getPageInfos]);

  const hierarchyInterventionsMap = useMemo(() => {
    if (!currentPageScan)
      return new Map<string, HeadingHierarchyRelevanceIntervention>();

    const interventions = getPageInfos(currentPageScan.page_id)
      .interventions.filter(
        (intervention) =>
          intervention.type === "informationStructuringTitles" &&
          intervention.titlesInterventionType === "titleHierarchyRelevance"
      )
      .map(
        (it) => [getHierarchyInterventionKey(it.level, it.x_path), it] as const
      );

    return new Map(interventions);
  }, [currentPageScan, getPageInfos]);

  const missingTitlesInterventions = useMemo(() => {
    if (!currentPageScan) return new Set<string>();

    const interventions = getPageInfos(currentPageScan?.page_id)
      .interventions.filter(
        (intervention) =>
          intervention.type === "informationStructuringTitles" &&
          intervention.titlesInterventionType === "missingTitle"
      )
      .filter(
        (it) =>
          !currentPageScan.scan.informationStructuring.titles.some(
            (title) => title.xPath === it.x_path
          )
      )
      .map((it) => it.x_path);

    return new Set(interventions);
  }, [currentPageScan, getPageInfos]);

  const textRelevanceInterventionsRequirmentsMap = useMemo(() => {
    if (!pageReport)
      return new Map<string, InterventionRequirement.headingTextRelevance>();

    const interventions = pageReport.neededInterventions
      .filter((ni) => ni.type === "headingTextRelevance")
      .map((it) => [it.x_path, it] as const);

    return new Map(interventions);
  }, [pageReport]);

  return {
    hierarchyErrorsMap,
    contentErrorsMap,
    contentInterventionsMap,
    hierarchyInterventionsMap,
    missingTitlesInterventions,
    textRelevanceInterventionsRequirmentsMap,
  };
};

export interface HeadingsRetributionContextProps {
  treeViewApiRef: RichTreeViewApiRef;

  contentErrorsMap: Map<string, NonCompliantError.notRelevantHeadingText>;
  hierarchyErrorsMap: Map<
    string,
    NonCompliantError.notRelevantHeadingHierarchy
  >;

  getContentIntervention: (
    titleScan: TitleScan
  ) => HeadingTextRelevanceIntervention | undefined;
  getHierarchyIntervention: (
    titleScan: TitleScan
  ) => HeadingHierarchyRelevanceIntervention | undefined;

  missingTitlesInterventions: Set<string>;

  textRelevanceInterventionsRequirmentsMap: Map<
    string,
    InterventionRequirement.headingTextRelevance
  >;

  isMissingTitlesEditMode: boolean;
  setIsMissingTitlesEditMode: React.Dispatch<React.SetStateAction<boolean>>;

  setMissingTitleIntervention: (xPath: string, isMissing: boolean) => void;
}

const HeadingsRetributionContext =
  createContext<HeadingsRetributionContextProps | null>(null);

const HeadingsRetributionProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const treeViewApiRef = useTreeViewApiRef();
  const { currentPageScan } = useScaner();
  const { reportCurrentPage } = useContext(ReportContext)!;
  const { removeInterventions, addIntervention } =
    useContext(InterventionsContext)!;

  const [isMissingTitlesEditMode, setIsMissingTitlesEditMode] = useState(false);

  const {
    contentErrorsMap,
    hierarchyErrorsMap,
    contentInterventionsMap,
    hierarchyInterventionsMap,
    missingTitlesInterventions,
    textRelevanceInterventionsRequirmentsMap,
  } = useHeadingsInformationsMaps();

  const getContentIntervention = useCallback(
    (scan: TitleScan) => {
      return contentInterventionsMap.get(
        getContentInterventionKey(scan.text, scan.xPath)
      );
    },
    [contentInterventionsMap]
  );

  const getHierarchyIntervention = useCallback(
    (scan: TitleScan) => {
      return hierarchyInterventionsMap.get(
        getHierarchyInterventionKey(scan.level, scan.xPath)
      );
    },
    [hierarchyInterventionsMap]
  );

  const setMissingTitleIntervention = useCallback(
    (xPath: string, isMissing: boolean) => {
      if (!currentPageScan) return;

      const removeCallback = (it: Intervention) =>
        it.type === "informationStructuringTitles" &&
        it.titlesInterventionType === "missingTitle" &&
        it.x_path === xPath;

      if (!isMissing) {
        removeInterventions(currentPageScan.page_id, removeCallback);
        reportCurrentPage();
        return;
      }

      const intervention: Intervention.informationStructuringTitles = {
        type: "informationStructuringTitles",
        titlesInterventionType: "missingTitle",
        x_path: xPath,
      };

      addIntervention(intervention, currentPageScan.page_id, removeCallback);
      reportCurrentPage();
    },
    [addIntervention, currentPageScan, removeInterventions, reportCurrentPage]
  );

  return (
    <HeadingsRetributionContext.Provider
      value={{
        treeViewApiRef,

        contentErrorsMap,
        hierarchyErrorsMap,

        getContentIntervention,
        getHierarchyIntervention,

        missingTitlesInterventions,
        textRelevanceInterventionsRequirmentsMap,

        isMissingTitlesEditMode,
        setIsMissingTitlesEditMode,

        setMissingTitleIntervention,
      }}
    >
      {children}
    </HeadingsRetributionContext.Provider>
  );
};

const useHeadingsRetribution = () => {
  const context = useContext(HeadingsRetributionContext);

  if (!context) {
    throw new Error(
      "useHeadingsRetribution must be used within a HeadingsRetributionProvider"
    );
  }

  return context;
};

export {
  HeadingsRetributionProvider,
  HeadingsRetributionContext,
  useHeadingsRetribution,
};
