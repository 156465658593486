import { Intervention, TitleScan } from "@ca/report";
import FormControl from "@mui/material/FormControl/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Radio from "@mui/material/Radio/Radio";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import Typography from "@mui/material/Typography/Typography";
import { useCurrentProjectPage } from "providers/CurrentFrameProjectPage/CurrentFrameProjectPage";
import { InterventionsContext } from "providers/InterventionsProvider";
import { ReportContext } from "providers/Report";
import React, { useContext } from "react";
import { useHeadingsRetribution } from "../HeadingsRetributionProvider";

export interface HeadingOverlayFormProps {
  scan: TitleScan;
  color: "warning" | "error" | "info";
}

const HeadingOverlayForm: React.FC<HeadingOverlayFormProps> = ({
  scan,
  color,
}) => {
  const { getHierarchyIntervention, getContentIntervention } =
    useHeadingsRetribution();

  const hierarchyIntervention = getHierarchyIntervention(scan);
  const contentIntervention = getContentIntervention(scan);

  const { addIntervention, removeInterventions } =
    useContext(InterventionsContext)!;

  const { reportCurrentPage } = useContext(ReportContext)!;

  const currentProjectPage = useCurrentProjectPage();

  const handleUpdateHierarchyIntervention = (
    value: "relevant" | "notRelevant" | "auto"
  ) => {
    const removeCallback = (it: Intervention) =>
      it.type === "informationStructuringTitles" &&
      it.titlesInterventionType === "titleHierarchyRelevance" &&
      it.x_path === scan.xPath &&
      it.level === scan.level;

    if (value === "auto") {
      removeInterventions(currentProjectPage.id, removeCallback);
      reportCurrentPage();
      return;
    }

    const intervention: Intervention = {
      type: "informationStructuringTitles",
      titlesInterventionType: "titleHierarchyRelevance",
      level: scan.level,
      x_path: scan.xPath,
      has_good_level: value === "relevant",
    };

    addIntervention(intervention, currentProjectPage.id, removeCallback);
    reportCurrentPage();
  };

  const handleUpdateContentIntervention = (
    value: "relevant" | "notRelevant"
  ) => {
    const removeCallback = (it: Intervention) =>
      it.type === "informationStructuringTitles" &&
      it.titlesInterventionType === "titleTextRelevance" &&
      it.x_path === scan.xPath &&
      it.text.trim() === scan.text.trim();

    const intervention: Intervention = {
      type: "informationStructuringTitles",
      titlesInterventionType: "titleTextRelevance",
      text: scan.text.trim(),
      x_path: scan.xPath,
      has_good_text: value === "relevant",
    };

    addIntervention(intervention, currentProjectPage.id, removeCallback);
    reportCurrentPage();
  };

  const contentInterventionValue =
    contentIntervention !== undefined
      ? contentIntervention.has_good_text
        ? "relevant"
        : "notRelevant"
      : undefined;

  const hierarchyInterventionValue =
    hierarchyIntervention === undefined
      ? "auto"
      : hierarchyIntervention.has_good_level
        ? "relevant"
        : "notRelevant";

  return (
    <React.Fragment>
      <FormControl component="fieldset" sx={{ width: "100%", mt: 2 }}>
        <Typography
          sx={{
            marginBottom: "0px",
            lineHeight: "1",
            fontSize: "1.1rem",
          }}
        >
          Le texte du titre est-il pertinent ?
        </Typography>
        <RadioGroup
          value={contentInterventionValue}
          onChange={(e) =>
            handleUpdateContentIntervention(e.target.value as any)
          }
        >
          <FormControlLabel
            sx={{
              marginBottom: "-12px",
              marginTop: "-2px",
            }}
            value={"relevant"}
            control={<Radio size="small" color={color} />}
            label="Pertinent"
          />
          <FormControlLabel
            value={"notRelevant"}
            control={<Radio size="small" color={color} />}
            label="Non pertinent"
          />
        </RadioGroup>
      </FormControl>

      <FormControl component="fieldset" sx={{ width: "100%", mt: 2 }}>
        <Typography
          sx={{
            marginBottom: "0px",
            lineHeight: "1",
            fontSize: "1.1rem",
          }}
        >
          La hierarchie du titre est-elle pertinente ?
        </Typography>
        <RadioGroup
          onChange={(e) =>
            handleUpdateHierarchyIntervention(e.target.value as any)
          }
          value={hierarchyInterventionValue}
        >
          <FormControlLabel
            sx={{
              marginBottom: "-12px",
              marginTop: "-2px",
            }}
            value={"auto"}
            control={<Radio size="small" color={color} />}
            label="Automatique"
          />
          <FormControlLabel
            sx={{
              marginBottom: "-12px",
            }}
            value={"relevant"}
            control={<Radio size="small" color={color} />}
            label="Pertinente"
          />
          <FormControlLabel
            value={"notRelevant"}
            control={<Radio size="small" color={color} />}
            label="Non pertinente"
          />
        </RadioGroup>
      </FormControl>
    </React.Fragment>
  );
};

export default HeadingOverlayForm;
