import { PopperPlacementType } from "@mui/material";
import Popper from "@mui/material/Popper/Popper";
import { CheckAccessFrameContext } from "providers/CheckAccessFrame/CheckAccessFrame";
import { useXPathFinder } from "providers/XPathFinder";
import React, { useContext, useEffect, useState } from "react";

export interface RetributionButtonPopperProps {
  xPath: string;

  placement:
    | PopperPlacementType
    | ((anchorEl: HTMLElement) => PopperPlacementType);
  offset?: number[] | ((anchorEl: HTMLElement) => number[]);

  children: React.ReactElement;
}

const RetributionButtonPopper: React.FC<RetributionButtonPopperProps> = ({
  xPath,
  placement,
  offset,
  children,
}) => {
  const { shadowContainer } = useContext(CheckAccessFrameContext)!;
  const element = useXPathFinder(xPath);
  const { frame } = useContext(CheckAccessFrameContext)!;

  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!element || !frame?.contentWindow) return;

      if (!element.checkVisibility()) {
        setIsInView(false);
        return;
      }

      const { top, left, bottom, right } = element.getBoundingClientRect();
      const { innerHeight, innerWidth } = frame.contentWindow;

      const inView =
        ((top > 0 && top < innerHeight) ||
          (bottom > 0 && bottom < innerHeight)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth));

      setIsInView(inView);
    };

    handleScroll();

    let scrollTimeout: NodeJS.Timeout;
    const handleScrollEnd = () => {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(handleScroll, 100);
    };

    frame?.contentWindow?.addEventListener("scroll", handleScrollEnd);

    return () => {
      frame?.contentWindow?.removeEventListener("scroll", handleScroll);
    };
  }, [element, frame, frame?.contentWindow]);

  if (!element || !isInView) return null;

  return (
    <Popper
      open={true}
      anchorEl={element}
      container={() => shadowContainer.current}
      placement={placement instanceof Function ? placement(element) : placement}
      sx={{ zIndex: 100000 }}
      popperOptions={{
        modifiers: [
          {
            name: "flip",
            enabled: false,
          },

          ...(offset
            ? [
                {
                  name: "offset",
                  options: {
                    offset:
                      offset instanceof Function ? offset(element) : offset,
                  },
                },
              ]
            : []),
        ],
      }}
    >
      {children}
    </Popper>
  );
};

export default RetributionButtonPopper;
