import { Topic } from "@ca/report";
import { ReactComponent as ImagesIcon } from "../assets/icons/topics-icons/images.svg";
import { ReactComponent as ColorsIcon } from "../assets/icons/topics-icons/colors.svg";
import { ReactComponent as FramesIcon } from "../assets/icons/topics-icons/frames.svg";
import { ReactComponent as MultimediaIcon } from "../assets/icons/topics-icons/multimedia.svg";
import { ReactComponent as NavigationIcon } from "../assets/icons/topics-icons/navigation.svg";
import { ReactComponent as InformationStructuringIcon } from "../assets/icons/topics-icons/information-structuring.svg";
import { ReactComponent as InformationDisplayIcon } from "../assets/icons/topics-icons/information-display.svg";
import { ReactComponent as ConsultationIcon } from "../assets/icons/topics-icons/consultation.svg";
import { ReactComponent as MandatoryElementsIcon } from "../assets/icons/topics-icons/mandatory-elements.svg";
import { ReactComponent as ScriptIcon } from "../assets/icons/topics-icons/scripts.svg";
import { ReactComponent as LinksIcon } from "../assets/icons/topics-icons/links.svg";
import { ReactComponent as FormsIcon } from "../assets/icons/topics-icons/forms.svg";
import { ReactComponent as TablesIcon } from "../assets/icons/topics-icons/tables.svg";
import { CA_THEME } from "pages/project/check-access/CheckAccessHomeView";

export const ALL_TOPICS: Topic[] = [
  "images",
  "frames",
  "colors",
  "multimedia",
  "table",
  "links",
  "scripts",
  "mandatoryElements",
  "informationStructuring",
  "informationPresentation",
  "forms",
  "navigation",
  "consultation",
];

export const ALL_PICTURES_NEEDED_INTERVENTION_TYPES = [
  "pictureUsefulness",
  "pictureAltTextRelevance",
] as const;
export type AllPicturesNeededInterventionTypes =
  (typeof ALL_PICTURES_NEEDED_INTERVENTION_TYPES)[number];

export const getTopicNumber = (topic: Topic) => ALL_TOPICS.indexOf(topic) + 1;
export const getTopicLink = (topic: Topic) =>
  `https://accessibilite.numerique.gouv.fr/methode/criteres-et-tests/#${getTopicNumber(
    topic
  )}`;

export interface TopicDetails {
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  displayTitle: string;
  color: string;
}

export const TOPIC_DETAILS: Record<Topic, TopicDetails> = {
  images: {
    Icon: ImagesIcon,
    displayTitle: "Images",
    color: "#fff8bc",
  },
  frames: {
    Icon: FramesIcon,
    displayTitle: "Cadres",
    color: "#fff8bc",
  },
  colors: {
    Icon: (props) => (
      <ColorsIcon
        style={{
          ["--current-stroke" as any]: props.stroke,
        }}
        {...props}
      />
    ),
    displayTitle: "Couleurs",
    color: "#fff8bc",
  },
  multimedia: {
    Icon: (props) => (
      <MultimediaIcon
        style={{
          ["--current-stroke" as any]: props.stroke,
        }}
        {...props}
        stroke={
          props.fill === "white" ? CA_THEME.palette.primary.main : "white"
        }
      />
    ),
    displayTitle: "Multimédia",
    color: "#fff8bc",
  },
  table: {
    Icon: (props) => (
      <TablesIcon
        style={{
          ["--selected-white" as any]:
            props.fill === "white" ? "white" : CA_THEME.palette.primary.main,
          ["--selected-main" as any]:
            props.fill === "white" ? CA_THEME.palette.primary.main : "white",
        }}
        {...props}
      />
    ),
    displayTitle: "Tableaux",
    color: "#fff8bc",
  },
  links: {
    Icon: (props) => (
      <LinksIcon
        style={{
          ["--current-stroke" as any]:
            props.fill === "white"
              ? CA_THEME.palette.primary.main
              : props.stroke,
        }}
        {...props}
      />
    ),
    displayTitle: "Liens",
    color: "#fff8bc",
  },
  scripts: {
    Icon: (props) => (
      <ScriptIcon
        style={{
          ["--current-stroke" as any]:
            props.fill === "white"
              ? CA_THEME.palette.primary.main
              : props.stroke,
        }}
        {...props}
      />
    ),
    displayTitle: "Scripts",
    color: "#fff8bc",
  },
  mandatoryElements: {
    Icon: (props) => (
      <MandatoryElementsIcon
        style={{
          ["--current-stroke" as any]:
            props.fill === "white"
              ? CA_THEME.palette.primary.main
              : props.stroke,
        }}
        {...props}
      />
    ),
    displayTitle: "Éléments obligatoires",
    color: "#fff8bc",
  },
  informationStructuring: {
    Icon: InformationStructuringIcon,
    displayTitle: "Structuration de l'information",
    color: "#fff8bc",
  },
  informationPresentation: {
    Icon: InformationDisplayIcon,
    displayTitle: "Présentation de l'information",
    color: "#fff8bc",
  },
  forms: {
    Icon: (props) => (
      <FormsIcon
        style={{
          ["--current-stroke-text" as any]:
            props.fill === "white"
              ? CA_THEME.palette.primary.main
              : props.stroke,
        }}
        {...props}
      />
    ),
    displayTitle: "Formulaires",
    color: "#fff8bc",
  },
  navigation: {
    Icon: (props) => (
      <NavigationIcon
        style={{
          ["--current-stroke" as any]:
            props.fill === "white"
              ? CA_THEME.palette.primary.main
              : props.stroke,
        }}
        {...props}
      />
    ),
    displayTitle: "Navigation",
    color: "#fff8bc",
  },
  consultation: {
    Icon: (props) => (
      <ConsultationIcon
        style={{
          ["--current-stroke" as any]:
            props.fill === "white"
              ? CA_THEME.palette.primary.main
              : props.stroke,
        }}
        {...props}
      />
    ),
    displayTitle: "Consultation",
    color: "#fff8bc",
  },
};
