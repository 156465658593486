import { CheckAccessFrameContext } from "providers/CheckAccessFrame/CheckAccessFrame";
import { ReportContext } from "providers/Report";
import React, { useCallback, useContext, useMemo } from "react";
import RetributionButton from "../RetributionButton";
import { ReactComponent as ContrastIcon } from "../../../../assets/icons/icon-contrast.svg";
import { get_contrast, TextScan } from "@ca/report";
import Box from "@mui/material/Box/Box";
import RetributionButtonPopper from "../RetributionButtonPopper";
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import useScaner from "hooks/useScaner";
import { InterventionsContext } from "providers/InterventionsProvider";
import {
  InformationChipProps,
  NodeInformationChips,
} from "../NodeInformationChips";

export interface ColorsRetributionProps {}

const ColorsRetribution: React.FC<ColorsRetributionProps> = () => {
  const { contentDocument } = useContext(CheckAccessFrameContext)!;
  const { reportCurrentPage } = useContext(ReportContext)!;
  const { currentPageScan } = useScaner();
  const { findErrorByPathAndType } = useContext(ReportContext)!;
  const { replaceTargetedIntervention } = useContext(InterventionsContext)!;

  const updateContrastIntervention = useCallback(
    (textScan: TextScan, isContrastGood: boolean) => {
      if (!currentPageScan) return;

      replaceTargetedIntervention(
        {
          type: "textContrast",
          x_path: textScan.xPath,
          has_good_contrast: isContrastGood,
        },
        currentPageScan.page_id
      );

      reportCurrentPage();
    },
    [replaceTargetedIntervention, currentPageScan, reportCurrentPage]
  );

  const constrastErrorsElements = useMemo(() => {
    if (!contentDocument || !currentPageScan) return [];

    return currentPageScan.scan.texts.textScans
      .map((text) => {
        const isContrastError = findErrorByPathAndType(
          text.xPath,
          "badTextContrast"
        );

        let whatShouldContrastBe;

        if (text.textSize < 18.5 || (text.textSize < 24.0 && !text.isBold)) {
          whatShouldContrastBe = "4.5:1";
        } else {
          whatShouldContrastBe = "3:1";
        }

        const stateType = isContrastError ? "error" : "info";
        const defaultValue = isContrastError ? "bad" : "good";

        const chips: InformationChipProps[] = [
          {
            label: "Text gras",
            content: text.isBold ? "oui" : "non",
          },
          {
            label: "Taille du text",
            content: `${text.textSize.toFixed(1)}px`,
          },
          {
            label: "Couleur du fond",
            content: (
              <Box
                component="span"
                sx={{
                  display: "inline-block",
                  width: "20px",
                  height: "20px",
                  marginLeft: "8px",
                  verticalAlign: "middle",
                  backgroundColor: text.bgColor,
                  border: "1px solid #000",
                  borderRadius: "6px",
                }}
              />
            ),
          },
          {
            label: "Couleur du text",
            content: (
              <Box
                component="span"
                sx={{
                  display: "inline-block",
                  width: "20px",
                  height: "20px",
                  marginLeft: "8px",
                  verticalAlign: "middle",
                  backgroundColor: text.fgColor,
                  border: "1px solid #000",
                  borderRadius: "6px",
                }}
              />
            ),
          },
        ];

        return (
          <RetributionButtonPopper
            key={text.xPath}
            xPath={text.xPath}
            placement="left"
            offset={[0, 4]}
          >
            <Box>
              <RetributionButton
                retrivalId={`textContrast-${text.xPath}`}
                Icon={(props) => (
                  <ContrastIcon {...props} fill="white" stroke="white" />
                )}
                xPath={text.xPath}
                tooltipProps={{
                  title: `Contrast (${get_contrast(text.bgColor, text.fgColor).toFixed(2)}:1)`,
                  content: (
                    <Box>
                      <NodeInformationChips chips={chips} textAlign="left" />

                      <Typography
                        sx={{
                          lineHeight: "1",
                        }}
                        marginTop={2}
                      >
                        <strong>Contrast minimum demandé:</strong>{" "}
                        {whatShouldContrastBe}
                      </Typography>

                      <Divider sx={{ mb: 1 }} />

                      <FormControl>
                        <FormLabel>Le contrast est t'il bon?</FormLabel>
                        <RadioGroup
                          row
                          name={`textContrastRadioGroup-${text.xPath}`}
                          value={defaultValue}
                        >
                          <FormControlLabel
                            value="good"
                            control={
                              <Radio
                                name={`textContrastRadioGroup-${text.xPath}-Relevant`}
                                color={stateType}
                                onClick={() =>
                                  updateContrastIntervention(text, true)
                                }
                              />
                            }
                            label="oui"
                          />
                          <FormControlLabel
                            value="bad"
                            control={
                              <Radio
                                name={`textContrastRadioGroup-${text.xPath}-NotRelevant`}
                                color={stateType}
                                onClick={() =>
                                  updateContrastIntervention(text, false)
                                }
                              />
                            }
                            label="Non"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  ),
                  referenceLink:
                    "https://accessibilite.numerique.gouv.fr/methode/criteres-et-tests/#3.2",
                }}
                type={stateType}
              />
            </Box>
          </RetributionButtonPopper>
        );
      })
      .filter((element) => element !== null);
  }, [
    contentDocument,
    currentPageScan,
    updateContrastIntervention,
    findErrorByPathAndType,
  ]);

  return <React.Fragment>{constrastErrorsElements}</React.Fragment>;
};

export default ColorsRetribution;
