import { createContext, ReactNode } from "react";
import { useContext } from "react";
import usePageSwitcher, { ProjectPage } from "./usePageSwitcher";
import usePageSwitchListner from "./usePageSwitchListner";

export interface CurrentFrameProjectPageContextProps {
  currentProjectPage: ProjectPage;
  updateCurrentPageRef: React.RefObject<(url: string | URL) => Promise<void>>;
}

const CurrentFrameProjectPageContext =
  createContext<CurrentFrameProjectPageContextProps | null>(null);

const CurrentFrameProjectPageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { currentProjectPage, updateCurrentPageRef } = usePageSwitcher();
  usePageSwitchListner(updateCurrentPageRef);

  return (
    <CurrentFrameProjectPageContext.Provider
      value={{
        currentProjectPage,
        updateCurrentPageRef,
      }}
    >
      {children}
    </CurrentFrameProjectPageContext.Provider>
  );
};

export const useCurrentProjectPage = () => {
  return useContext(CurrentFrameProjectPageContext)!.currentProjectPage;
};

export { CurrentFrameProjectPageContext, CurrentFrameProjectPageProvider };
