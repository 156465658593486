import { useTheme } from "@mui/material";
import RetributionNodeOverlay from "components/check-access/scanReportRetribution/RetributionNodeOverlay";
import { CheckAccessFrameContext } from "providers/CheckAccessFrame/CheckAccessFrame";
import React from "react";
import { useCallback, useContext, useMemo } from "react";

export enum DisplayedNodeType {
  Lists,
  Header,
  Footer,
  Main,
  Nav,
}

const TITLES_HEADINGS = ["H1", "H2", "H3", "H4", "H5", "H6"];

const BORDER_SIZE_PX = 6;

const NODE_TYPE_SELECTOR: Record<DisplayedNodeType, string> = {
  [DisplayedNodeType.Lists]: "ul, ol, dl, [role='list']",
  [DisplayedNodeType.Header]: "header",
  [DisplayedNodeType.Footer]: "footer",
  [DisplayedNodeType.Main]: "main",
  [DisplayedNodeType.Nav]: "nav",
};

interface Size {
  width: number;
  height: number;
}
const NODE_TYPE_ADDITIONAL_SIZE: Record<DisplayedNodeType, Size> = {
  [DisplayedNodeType.Lists]: { width: 24, height: 24 },

  [DisplayedNodeType.Header]: { width: -BORDER_SIZE_PX * 2, height: 0 },
  [DisplayedNodeType.Footer]: { width: -BORDER_SIZE_PX * 2, height: 0 },
  [DisplayedNodeType.Main]: { width: -BORDER_SIZE_PX * 2, height: 0 },
  [DisplayedNodeType.Nav]: { width: -BORDER_SIZE_PX * 2, height: 0 },
};

const getNodeDisplayTitle = (node: HTMLElement): string => {
  if (TITLES_HEADINGS.includes(node.tagName)) {
    return node.tagName;
  }

  if (
    node.getAttribute("role") === "heading" &&
    node.hasAttribute("aria-level")
  ) {
    return `H${node.getAttribute("aria-level")}`;
  }

  if (node.tagName === "UL") {
    return "Liste non ordonée";
  }

  if (node.tagName === "OL") {
    return "Liste ordonée";
  }

  if (node.tagName === "DL") {
    return "Liste de description";
  }

  if (node.getAttribute("role") === "list") {
    return "Liste";
  }

  return node.tagName;
};

const useInformationStructuringFrameRetribution = (
  displayedNodeType: DisplayedNodeType | null
): React.ReactElement[] => {
  const { palette } = useTheme();

  const { frame } = useContext(CheckAccessFrameContext)!;
  const color = palette.warning.main;

  const getHighlightNodeElement = useCallback(
    (node: HTMLElement, index: number): React.ReactElement => {
      return (
        <RetributionNodeOverlay
          key={index}
          anchorEl={node}
          color={color}
          title={getNodeDisplayTitle(node)}
          padding={NODE_TYPE_ADDITIONAL_SIZE[displayedNodeType!]}
          borderSize={BORDER_SIZE_PX}
        />
      );
    },
    [color, displayedNodeType]
  );

  return useMemo(() => {
    if (!frame?.contentDocument || displayedNodeType === null) return [];

    return Array.from(
      frame.contentDocument.querySelectorAll(
        NODE_TYPE_SELECTOR[displayedNodeType]
      )
    )
      .filter(
        (node): node is HTMLElement => node.nodeType === Node.ELEMENT_NODE
      )
      .map(getHighlightNodeElement);
  }, [getHighlightNodeElement, frame, displayedNodeType]);
};

export default useInformationStructuringFrameRetribution;
