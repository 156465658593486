import Paper from "@mui/material/Paper/Paper";
import Tab from "@mui/material/Tab/Tab";
import Tabs from "@mui/material/Tabs/Tabs";
import Typography from "@mui/material/Typography/Typography";
import useCurrentProject from "hooks/useCurrentProject";
import { ACTIVATED_TOPICS, ReportContext } from "providers/Report";
import React, { useContext, useEffect, useState } from "react";
import { get_intervention_linked_topic, Topic } from "@ca/report";
import { ReactComponent as HomeIcon } from "../../../assets/icons/icon-home.svg";
import Box from "@mui/material/Box/Box";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge/Badge";
import PicturesSidebarTopicDetails from "./topics-details/PicturesSidebarTopicDetails";
import { TOPIC_DETAILS } from "definitions/topic_details";
import { useTheme } from "@mui/material/styles";
import SummarySidebarDetails from "./topics-details/SummarySidebarDetails";
import ColorsSidebarTopicDetails from "./topics-details/ColorsSidebarTopicDetails";
import { CARetributionActiveTopicsContext } from "providers/CARetributionActiveTopics";
import MandatoryElementsSidebarTopicDetails from "./topics-details/MandatoryElementsSidebarTopicDetails";
import InformationStructuringSidebarTopicDetails from "./topics-details/informationStructuringSidebarTopicDetails/InformationStructuringSidebarTopicDetails";
import FramesSidebarTopicDetails from "./topics-details/FramesSidebarTopicDetails";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { NavLink } from "react-router-dom";
import { createProjectRoute } from "router";

interface TopicTab {
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  topic?: Topic;
  details: JSX.Element;
}

const TOPIC_SIDEBAR_DETAILS: Record<Topic, JSX.Element> = {
  images: <PicturesSidebarTopicDetails />,
  colors: <ColorsSidebarTopicDetails />,
  frames: <FramesSidebarTopicDetails />,
  multimedia: <></>,
  table: <></>,
  links: <></>,
  scripts: <></>,
  mandatoryElements: <MandatoryElementsSidebarTopicDetails />,
  informationStructuring: <InformationStructuringSidebarTopicDetails />,
  informationPresentation: <></>,
  forms: <></>,
  navigation: <></>,
  consultation: <></>,
};

const HOME_TAB = {
  Icon: HomeIcon,
  details: <SummarySidebarDetails />,
};

const TABS: TopicTab[] = [
  HOME_TAB,
  ...ACTIVATED_TOPICS.map((topic) => ({
    Icon: TOPIC_DETAILS[topic].Icon,
    topic,
    details: TOPIC_SIDEBAR_DETAILS[topic],
  })),
];

const ThematicTabs = styled(Tabs)(() => ({
  "& .MuiTab-root": {
    padding: "5px 8px 5px 5px",
    minWidth: "40px",
    minHeight: "40px",
    overflow: "visible",
  },
  "& .MuiTabs-flexContainer": {
    height: "100%",
    justifyContent: "center",
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "&.MuiTabs-root": {
    overflow: "visible !important",
    zIndex: 2,
  },
  "& .MuiTabs-scroller": {
    overflow: "visible !important",
  },
}));

const ThematicTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.secondary.main,
  "&.Mui-selected": {
    color: theme.palette.primary.main,
  },
}));

export const SIDEBAR_MAX_WIDTH = 450;

export interface ScanReportSidebarProps {}

const ScanReportSidebar: React.FC<ScanReportSidebarProps> = () => {
  const project = useCurrentProject();
  const { pageReport } = useContext(ReportContext)!;
  const { activeAll, activeOnlyOneTopic } = useContext(
    CARetributionActiveTopicsContext
  )!;

  const { palette } = useTheme();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const displayedTabs = pageReport ? TABS : TABS.slice(0, 1);
  const currentTab = displayedTabs[selectedTabIndex] || HOME_TAB;

  useEffect(() => {
    if (pageReport) return;
    setSelectedTabIndex(0);
  }, [pageReport]);

  const getTopicNeededInterventions = (topics: Topic[]) => {
    const interventions =
      pageReport?.neededInterventions.filter((intervention) =>
        topics.includes(get_intervention_linked_topic(intervention))
      ) || [];
    return interventions.length;
  };

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTabIndex(newValue);
    const tabDetails = TABS[newValue];
    if (tabDetails.topic) {
      activeOnlyOneTopic(tabDetails.topic);
    } else {
      activeAll();
    }
  };

  return (
    <Paper
      className="shadow-md"
      sx={{
        backgroundColor: "secondary.main",
        borderRight: "2px solid",
        borderColor: "primary.main",
        borderRadius: 0,
        minWidth: `${SIDEBAR_MAX_WIDTH}px`,
        maxWidth: `${SIDEBAR_MAX_WIDTH}px`,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          background: `linear-gradient(90deg, ${palette.primary.dark} 45px, transparent)`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "90%",
            marginTop: 1,
          }}
        >
          <NavLink to={createProjectRoute(project.id)}>
            <ExitToAppIcon
              sx={{
                width: "38px",
                height: "38px",
              }}
            />
          </NavLink>
        </Box>
        <ThematicTabs
          sx={{
            flexGrow: 1,
          }}
          orientation="vertical"
          variant="standard"
          value={selectedTabIndex}
          aria-label="RGAA themes selection"
          onChange={handleTabChange}
        >
          {displayedTabs.map((tab, index) => (
            <ThematicTab
              key={index}
              icon={
                <Badge
                  color="warning"
                  badgeContent={
                    tab.topic
                      ? getTopicNeededInterventions([tab.topic])
                      : undefined
                  }
                >
                  {selectedTabIndex === index ? (
                    <tab.Icon
                      stroke="white"
                      fill="white"
                      width="38px"
                      height="38px"
                    />
                  ) : (
                    <tab.Icon
                      stroke="white"
                      fill={palette.primary.main}
                      width="38px"
                      height="38px"
                    />
                  )}
                </Badge>
              }
            />
          ))}
        </ThematicTabs>
      </Box>

      <Box sx={{ width: "100%", flexGrow: "1" }} className="overflow-y-auto">
        <Typography
          variant="h3"
          color={"primary.light"}
          textOverflow={"ellipsis"}
          overflow={"hidden"}
          textAlign={"center"}
        >
          {project?.name}
        </Typography>
        {currentTab.details}
      </Box>
      <Box sx={{ flexGrow: 1, display: "flex", overflow: "hidden" }}></Box>
    </Paper>
  );
};

export default ScanReportSidebar;
