import useScaner from "hooks/useScaner";
import { CheckAccessFrameContext } from "providers/CheckAccessFrame/CheckAccessFrame";
import { ReportContext } from "providers/Report";
import React, { useCallback, useContext, useMemo } from "react";
import RetributionButton from "../RetributionButton";
import { ReactComponent as TextIcon } from "../../../../assets/icons/icon-text.svg";
import Box from "@mui/material/Box/Box";
import Typography from "@mui/material/Typography/Typography";
import { InterventionsContext } from "providers/InterventionsProvider";
import { Intervention, TextScan } from "@ca/report";
import FormControl from "@mui/material/FormControl/FormControl";
import FormLabel from "@mui/material/FormLabel/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import Radio from "@mui/material/Radio/Radio";
import RetributionButtonPopper from "../RetributionButtonPopper";

export interface MandatoryElementsRetributionProps {}

const MandatoryElementsRetribution: React.FC<
  MandatoryElementsRetributionProps
> = () => {
  const { currentPageScan } = useScaner();
  const { allTargetedErrors, reportCurrentPage } = useContext(ReportContext)!;
  const { contentDocument } = useContext(CheckAccessFrameContext)!;
  const { getPageInfos, addIntervention } = useContext(InterventionsContext)!;

  const textLangRelevanceInterventions: Map<string, boolean> = useMemo(() => {
    if (!currentPageScan) return new Map();

    return new Map(
      getPageInfos(currentPageScan.page_id)
        .interventions.filter(
          (intervention): intervention is Intervention.textLangRelevance =>
            intervention.type === "textLangRelevance"
        )
        .map((intervention) => [
          `${intervention.content.toLowerCase().trim()}-${intervention.lang}`,
          intervention.is_lang_relevant,
        ])
    );
  }, [currentPageScan, getPageInfos]);

  const updateTextLangIntervention = useCallback(
    (textScan: TextScan, isLangRelevant: boolean) => {
      if (!currentPageScan) return;

      addIntervention(
        {
          type: "textLangRelevance",
          content: textScan.content.toLowerCase().trim(),
          lang: textScan.nearestLang,
          is_lang_relevant: isLangRelevant,
        },
        currentPageScan.page_id,
        (it) =>
          it.type === "textLangRelevance" &&
          it.content === textScan.content.toLowerCase().trim() &&
          it.lang === textScan.nearestLang
      );

      reportCurrentPage();
    },
    [addIntervention, currentPageScan, reportCurrentPage]
  );

  const allTexts = useMemo(() => {
    if (!contentDocument || !currentPageScan) return [];

    return currentPageScan.scan.texts.textScans.map((text) => {
      const isLangIndicatedError = allTargetedErrors.find(
        (err) =>
          err.type === "languageChangeNotSpecified" && err.x_path === text.xPath
      );
      const isLangRelevantError = allTargetedErrors.find(
        (err) =>
          err.type === "languageChangeNotRelevant" && err.x_path === text.xPath
      );

      const langRelevantIntervention = text.nearestLang
        ? textLangRelevanceInterventions.get(
            `${text.content.toLowerCase().trim()}-${text.nearestLang}`
          )
        : undefined;

      const interventionFormValue =
        langRelevantIntervention === undefined
          ? ""
          : langRelevantIntervention
            ? "relevant"
            : "notRelevant";

      const stateType =
        isLangIndicatedError || isLangRelevantError ? "error" : "info";

      return (
        <RetributionButtonPopper
          xPath={text.xPath}
          key={text.xPath}
          placement={"left"}
          offset={[0, 4]}
        >
          <Box>
            <RetributionButton
              retrivalId={`textMandatoryElement-${text.xPath}`}
              Icon={(props) => (
                <TextIcon {...props} fill="white" stroke="white" />
              )}
              xPath={text.xPath}
              tooltipProps={{
                title: "Element obligatoire",
                content: (
                  <Box>
                    <Typography
                      sx={{
                        marginBottom: "5px",
                        lineHeight: "1",
                      }}
                    >
                      Langue du text par défaut:{" "}
                      <strong>{text.nearestLang}</strong>
                    </Typography>

                    <Typography
                      sx={{
                        marginBottom: "0px",
                        lineHeight: "1",
                      }}
                    >
                      Langue du text détecté:{" "}
                      <strong>{text.langDetected}</strong>
                    </Typography>

                    <Typography
                      sx={{
                        marginTop: "20px",
                        marginBottom: "10px",
                        lineHeight: "1",
                      }}
                    >
                      La langue associée à se texte à été jugée{" "}
                      {isLangRelevantError ? "non " : ""}pertinente voulez vous
                      la changer ?
                    </Typography>
                    <FormControl>
                      <FormLabel>Pertinence</FormLabel>
                      <RadioGroup
                        row
                        name={`textLangRelevanceRadioGroup-${text.xPath}`}
                        value={interventionFormValue}
                      >
                        <FormControlLabel
                          value="relevant"
                          control={
                            <Radio
                              name={`textLangRelevanceRadioGroup-${text.xPath}-Relevant`}
                              color={stateType}
                              onClick={() =>
                                updateTextLangIntervention(text, true)
                              }
                            />
                          }
                          label="Pertinent"
                        />
                        <FormControlLabel
                          value="notRelevant"
                          control={
                            <Radio
                              name={`textLangRelevanceRadioGroup-${text.xPath}-NotRelevant`}
                              color={stateType}
                              onClick={() =>
                                updateTextLangIntervention(text, false)
                              }
                            />
                          }
                          label="Non pertinent"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                ),
                referenceLink:
                  "https://accessibilite.numerique.gouv.fr/methode/criteres-et-tests/#8.7",
              }}
              type={stateType}
            />
          </Box>
        </RetributionButtonPopper>
      );
    });
  }, [
    allTargetedErrors,
    contentDocument,
    currentPageScan,
    textLangRelevanceInterventions,
    updateTextLangIntervention,
  ]);

  return <React.Fragment>{allTexts}</React.Fragment>;
};

export default MandatoryElementsRetribution;
