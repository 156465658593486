import useOrganizations from "hooks/useOrganizations";
import { SidebarCategory } from "./Sidebar";
import SidebarButton from "./SidebarButton";
import { createOrganizationRoute } from "router";
import AdaptifyIcon from "@mui/icons-material/AccessibilityNew";
import HorizontalSplit from "@mui/icons-material/HorizontalSplit";

const MainSidebar: React.FC = () => {
  const organizations = useOrganizations();

  return (
    <>
      <SidebarCategory title="Documentation">
        <SidebarButton
          Icon={AdaptifyIcon}
          link={"https://adaptify-docs.hub-access.com/"}
          text="Adaptify"
          target="_blank"
        />
      </SidebarCategory>
      <SidebarCategory title="Organisation">
        {organizations.map((organization, i) => (
          <SidebarButton
            key={i}
            Icon={HorizontalSplit}
            link={createOrganizationRoute(organization.id)}
            text={organization.name}
            target="_self"
          />
        ))}
      </SidebarCategory>
    </>
  );
};

export default MainSidebar;
