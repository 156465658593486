import Chip from "@mui/material/Chip/Chip";
import Stack from "@mui/material/Stack/Stack";
import { styled } from "@mui/material/styles";
import React from "react";

export interface InformationChipProps {
  label: string;
  content: React.ReactNode;
  align?: "left" | "center";
}

const InformationChipStyle = styled(Chip)<{
  align?: "left" | "center";
}>(({ theme, align }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  "& .MuiChip-label": {
    textAlign: align || "left",
    fontSize: "15px",
  },
}));

export const InformationChip: React.FC<InformationChipProps> = ({
  label,
  content,
  align,
}) => (
  <InformationChipStyle
    align={align}
    icon={<Chip label={label} size="small" />}
    label={content}
  />
);

export interface NodeInformationChipsProps {
  chips: InformationChipProps[];
  textAlign: "left" | "center";
}

export const NodeInformationChips: React.FC<NodeInformationChipsProps> = ({
  chips,
  textAlign,
}) => {
  const stackDirection = textAlign === "left" ? "column" : "row";

  return (
    <Stack
      direction={stackDirection}
      spacing={1}
      useFlexGap
      sx={{
        flexWrap: textAlign === "left" ? "nowrap" : "wrap",
        alignItems: textAlign === "left" ? "flex-start" : "center",
        justifyContent: textAlign === "left" ? "flex-start" : "center",
      }}
    >
      {chips.map((chip, index) => (
        <InformationChip key={index} {...chip} align={textAlign} />
      ))}
    </Stack>
  );
};
