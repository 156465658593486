import React, { useContext } from "react";
import Box from "@mui/material/Box/Box";
import { ReportContext } from "providers/Report";
import useScaner from "hooks/useScaner";
import { InterventionsContext } from "providers/InterventionsProvider";
import { Intervention } from "@ca/report";
import ButtonGroup from "@mui/material/ButtonGroup/ButtonGroup";
import Typography from "@mui/material/Typography/Typography";
import Button from "@mui/material/Button/Button";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";

export interface HeadingsDefnieAllTextInterventionsProps {}

const HeadingsDefnieAllTextInterventions: React.FC<
  HeadingsDefnieAllTextInterventionsProps
> = () => {
  const { currentPageScan } = useScaner();
  const { reportCurrentPage } = useContext(ReportContext)!;
  const { removeInterventions, bulkAddInterventions } =
    useContext(InterventionsContext)!;

  const handleUpdateAllTextInterventions = (
    allRelevant: boolean | undefined
  ) => {
    if (!currentPageScan) return;

    const toRemoveXPaths =
      currentPageScan.scan.informationStructuring.titles.map((it) => it.xPath);

    const removeCallback = (it: Intervention) =>
      it.type === "informationStructuringTitles" &&
      it.titlesInterventionType === "titleTextRelevance" &&
      toRemoveXPaths.includes(it.x_path);

    if (allRelevant === undefined) {
      removeInterventions(currentPageScan.page_id, removeCallback);
      reportCurrentPage();
      return;
    }

    const interventions: Intervention.informationStructuringTitles[] =
      currentPageScan.scan.informationStructuring.titles.map((title) => ({
        type: "informationStructuringTitles",
        titlesInterventionType: "titleTextRelevance",
        x_path: title.xPath,
        text: title.text.trim(),
        has_good_text: allRelevant,
      }));

    bulkAddInterventions(
      interventions,
      currentPageScan.page_id,
      removeCallback
    );
    reportCurrentPage();
  };

  return (
    <Box
      sx={{
        marginBottom: 2,
      }}
    >
      <Typography>Définir tous les textes des titres comme</Typography>
      <ButtonGroup
        color="warning"
        size={"small"}
        sx={{
          "& .MuiButton-root": {
            fontSize: "0.8rem",
            padding: "4px 10px",
          },
        }}
        variant="contained"
        aria-label="All titles text relevance button group"
      >
        <Button onClick={() => handleUpdateAllTextInterventions(undefined)}>
          <SettingsBackupRestoreIcon />
        </Button>
        <Button onClick={() => handleUpdateAllTextInterventions(true)}>
          Pertinent
        </Button>
        <Button onClick={() => handleUpdateAllTextInterventions(false)}>
          Non pertinent
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default HeadingsDefnieAllTextInterventions;
