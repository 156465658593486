import { Topic } from "@ca/report";
import Box from "@mui/material/Box/Box";
import Typography from "@mui/material/Typography/Typography";
import {
  getTopicLink,
  getTopicNumber,
  TOPIC_DETAILS,
} from "definitions/topic_details";
import React from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TopicScoreBarChart from "../TopicScoreBarChart";

export interface SidebarTopicHeaderProps {
  topic: Topic;
  titleFontSize?: string;
}

const SidebarTopicHeader: React.FC<SidebarTopicHeaderProps> = ({
  topic,
  titleFontSize,
}) => {
  const topicDetails = TOPIC_DETAILS[topic];

  return (
    <>
      <Box
        sx={{
          color: "common.white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          mt: 1,
          mb: 2,
        }}
      >
        <topicDetails.Icon
          stroke={topicDetails.color}
          width="40px"
          height="40px"
        />
        <Typography
          variant="h5"
          fontSize={`${titleFontSize || "1.75rem"} !important`}
          textAlign={"center"}
        >
          {`${getTopicNumber(topic)}. ${topicDetails.displayTitle.toUpperCase()}`}
        </Typography>

        <a href={getTopicLink(topic)} target="_blank" rel="noreferrer">
          <OpenInNewIcon color="primary" sx={{ fontSize: 21 }} />
        </a>
      </Box>
      <TopicScoreBarChart topics={[topic]} />
    </>
  );
};

export default SidebarTopicHeader;
