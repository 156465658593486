import React, { useCallback, useContext, useMemo } from "react";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import useScaner from "hooks/useScaner";
import { TitleScan } from "@ca/report";
import { TreeViewBaseItem } from "@mui/x-tree-view/models";
import { treeItemClasses } from "@mui/x-tree-view/TreeItem/treeItemClasses";
import styled from "@mui/material/styles/styled";
import { alpha, Avatar, Typography, useTheme } from "@mui/material";
import { TreeItem2Provider } from "@mui/x-tree-view/TreeItem2Provider";
import {
  TreeItem2Content,
  TreeItem2GroupTransition,
  TreeItem2IconContainer,
  TreeItem2Label,
  TreeItem2Root,
} from "@mui/x-tree-view/TreeItem2";
import { TreeItem2Icon } from "@mui/x-tree-view/TreeItem2Icon";
import {
  useTreeItem2,
  UseTreeItem2Parameters,
} from "@mui/x-tree-view/useTreeItem2";
import { XPathFinderContext } from "providers/XPathFinder";
import { CRITERIA_STATE_DETAILS } from "definitions/criteria_state_details";
import { HEADING_OVERLAY_RETRIVAL_KEY } from "../HeadingOverlay/HeadingOverlay";
import { useHeadingsRetribution } from "../HeadingsRetributionProvider";

type ExtendedTreeItemProps = {
  id: string;
  label: string;

  level: number;
  isErrored: boolean;
  isInterventionRequired: boolean;
};

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  [`& .${treeItemClasses.label}`]: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },

  [`&.content-errored`]: {
    color: theme.palette.error.contrastText,
    backgroundColor: alpha(theme.palette.error.main, 0.8),
    [`&:hover`]: {
      backgroundColor: alpha(theme.palette.error.main, 0.9),
    },
    [`&.content-selected, &.content-focused`]: {
      backgroundColor: alpha(theme.palette.error.main, 1),
    },
  },

  [`&.content-intervention-required:not(.content-errored)`]: {
    [`&:hover`]: {
      backgroundColor: alpha(theme.palette.warning.main, 0.16),
    },
    [`&.content-selected, &.content-focused`]: {
      backgroundColor: alpha(theme.palette.warning.main, 0.32),
    },
  },

  [`&:not(.content-errored):not(.content-intervention-required)`]: {
    [`&:hover`]: {
      backgroundColor: alpha(theme.palette.info.main, 0.16),
    },
    [`&.content-selected, &.content-focused`]: {
      backgroundColor: alpha(theme.palette.info.main, 0.32),
    },
  },
}));

interface CustomTreeItemProps
  extends Omit<UseTreeItem2Parameters, "rootRef">,
    Omit<React.HTMLAttributes<HTMLLIElement>, "onFocus"> {}

const CustomTreeItem = React.forwardRef(function CustomTreeItem(
  props: CustomTreeItemProps,
  ref: React.Ref<HTMLLIElement>
) {
  const { id, itemId, label, disabled, children, ...other } = props;
  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
    publicAPI,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  const { scrollPictoIntoView } = useContext(XPathFinderContext)!;
  const {
    id: xPath,
    level,
    isErrored,
    isInterventionRequired,
  } = publicAPI.getItem(itemId) as ExtendedTreeItemProps;

  const { palette } = useTheme();
  const { Icon, paletteTheme } = CRITERIA_STATE_DETAILS.needIntervention;

  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root {...getRootProps(other)}>
        <CustomTreeItemContent
          {...getContentProps()}
          onClick={() =>
            scrollPictoIntoView(HEADING_OVERLAY_RETRIVAL_KEY, xPath, {
              top: 0.2,
              left: 0.5,
            })
          }
          sx={{
            minHeight: "36px",
          }}
          className={`\
            ${status.selected ? "content-selected" : ""} \
            ${isErrored ? "content-errored" : ""} \
            ${isInterventionRequired ? "content-intervention-required" : ""} \
            ${status.focused ? "content-focused" : ""} \
          `}
        >
          <TreeItem2IconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          <Typography
            sx={{
              fontWeight: "bold",
            }}
          >
            {level}
          </Typography>
          <TreeItem2Label
            {...getLabelProps()}
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          />

          {isInterventionRequired && (
            <Avatar
              sx={{
                backgroundColor: palette[paletteTheme].main,
                width: 28,
                height: 28,
              }}
            >
              <Icon stroke={palette.common.white} width={26} height={26} />
            </Avatar>
          )}
        </CustomTreeItemContent>
        {children && (
          <TreeItem2GroupTransition {...getGroupTransitionProps()} />
        )}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

const HeadingsTreeSidebarRetribution: React.FC = () => {
  const {
    treeViewApiRef,
    hierarchyErrorsMap,
    contentErrorsMap,
    textRelevanceInterventionsRequirmentsMap,
  } = useHeadingsRetribution();

  const { currentPageScan } = useScaner();

  const buildHeadingNode = useCallback(
    (title: TitleScan): TreeViewBaseItem<ExtendedTreeItemProps> => ({
      id: title.xPath,

      label: title.text,
      level: title.level,

      isErrored:
        hierarchyErrorsMap.has(title.xPath) ||
        contentErrorsMap.has(title.xPath),

      isInterventionRequired: textRelevanceInterventionsRequirmentsMap.has(
        title.xPath
      ),

      children: [],
    }),
    [
      contentErrorsMap,
      hierarchyErrorsMap,
      textRelevanceInterventionsRequirmentsMap,
    ]
  );

  const headingsTree = useMemo(() => {
    if (!currentPageScan) return [];

    const root = {
      level: 0,
      children: [],
    } as unknown as TreeViewBaseItem<ExtendedTreeItemProps>;
    const stack = [root];

    currentPageScan.scan.informationStructuring.titles.forEach((title) => {
      while (stack[stack.length - 1].level >= title.level) {
        stack.pop();
      }

      const node = buildHeadingNode(title);

      stack[stack.length - 1].children?.push(node);
      stack.push(node);
    });

    return root.children || [];
  }, [buildHeadingNode, currentPageScan]);

  return (
    <RichTreeView
      apiRef={treeViewApiRef}
      items={headingsTree}
      slots={{ item: CustomTreeItem }}
      expansionTrigger="iconContainer"
      defaultExpandedItems={currentPageScan?.scan.informationStructuring.titles.map(
        (t) => t.xPath
      )}
    />
  );
};

export default HeadingsTreeSidebarRetribution;
