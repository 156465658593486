import { TitleScan } from "@ca/report";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack/Stack";
import { useTheme } from "@mui/material/styles";
import RetributionNodeOverlay from "components/check-access/scanReportRetribution/RetributionNodeOverlay";
import RetributionTooltip from "components/check-access/scanReportRetribution/RetributionTooltip";
import { useXPathFinder } from "providers/XPathFinder";
import React, { useState } from "react";
import HeadingOverlayForm from "./HeadingOverlayForm";
import Alert from "@mui/material/Alert/Alert";
import { useHeadingsRetribution } from "../HeadingsRetributionProvider";
import { NodeInformationChips } from "components/check-access/scanReportRetribution/NodeInformationChips";

const OVERLAY_PADDING = { width: 24, height: 24 };
const OVERLAY_BORDER_SIZE = 6;

export const HEADING_OVERLAY_RETRIVAL_KEY = "heading-overlay";

const computeBoundingRect = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();

  rect.height += OVERLAY_PADDING.height + OVERLAY_BORDER_SIZE;
  rect.width += OVERLAY_PADDING.width + OVERLAY_BORDER_SIZE;

  rect.y -= (OVERLAY_PADDING.height + OVERLAY_BORDER_SIZE) / 2;
  rect.x -= (OVERLAY_PADDING.width + OVERLAY_BORDER_SIZE) / 2;

  return rect;
};

export interface HeadingOverlayProps {
  scan: TitleScan;
}

const HeadingOverlay: React.FC<HeadingOverlayProps> = ({ scan }) => {
  const {
    hierarchyErrorsMap,
    contentErrorsMap,
    textRelevanceInterventionsRequirmentsMap,
    treeViewApiRef,
  } = useHeadingsRetribution();

  const contentError = contentErrorsMap.get(scan.xPath);
  const hierarchyError = hierarchyErrorsMap.get(scan.xPath);
  const needTextRelevanceIntervention =
    textRelevanceInterventionsRequirmentsMap.get(scan.xPath);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const element = useXPathFinder(scan.xPath);
  const { palette } = useTheme();

  if (!element) return null;

  const isError = hierarchyError !== undefined || contentError !== undefined;
  const color = isError
    ? "error"
    : needTextRelevanceIntervention
      ? "warning"
      : "info";

  let title;
  if (needTextRelevanceIntervention) {
    title =
      "Nous avons besoin de plus d'informations pour finir de catégoriser ce titre";
  } else if (hierarchyError && contentError) {
    title = "Il y a deux erreurs d'accessibilité sur ce titre";
  } else if (hierarchyError) {
    title = "Il y a une erreur d'accessibilité sur ce titre";
  } else if (contentError) {
    title = "Il y a une erreur d'accessibilité sur ce titre";
  } else {
    title =
      "Tout est bon! Vous pouvez modifier les informations du titre si vous le souhaitez";
  }

  const handleClickNode = (event: React.SyntheticEvent) => {
    if (!isTooltipOpen && treeViewApiRef.current) {
      treeViewApiRef.current.focusItem(event, scan.xPath);
    }
    setIsTooltipOpen(!isTooltipOpen);
  };

  return (
    <RetributionTooltip
      open={isTooltipOpen}
      title={title}
      content={
        <Box sx={{ width: "100%" }}>
          <Stack spacing={1} sx={{ marginBottom: 1 }}>
            {hierarchyError && (
              <Alert severity="error">{hierarchyError.details}</Alert>
            )}
            {contentError && (
              <Alert severity="error">
                Le texte a été défini comme non pertinent
              </Alert>
            )}
            {needTextRelevanceIntervention && (
              <Alert severity="warning">
                Veuillez indiquer si le texte est pertinent ou non
              </Alert>
            )}
          </Stack>

          <NodeInformationChips
            chips={[
              {
                label: "Niveau",
                content: scan.level,
              },
              {
                label: "Rôle d'en-tête",
                content: scan.isHeadingRole ? "Oui" : "Non",
              },
              {
                label: "Nom de balise",
                content: element.tagName,
              },
            ]}
            textAlign="center"
          />

          <HeadingOverlayForm scan={scan} color={color} />
        </Box>
      }
      referenceLink={""}
      anchorEl={{
        getBoundingClientRect: () => computeBoundingRect(element),
      }}
      onClickAway={() => setIsTooltipOpen(false)}
      color={palette[color].main}
    >
      <Box>
        <RetributionNodeOverlay
          retrievalId={`${HEADING_OVERLAY_RETRIVAL_KEY}-${scan.xPath}`}
          title={element.tagName}
          anchorEl={element}
          color={palette[color].main}
          borderSize={OVERLAY_BORDER_SIZE}
          padding={OVERLAY_PADDING}
          onClick={handleClickNode}
        />
      </Box>
    </RetributionTooltip>
  );
};

export default HeadingOverlay;
