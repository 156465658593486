// SVG elements are in the namespace http://www.w3.org/2000/svg
const NAMESPACES: Record<string, string> = {
  svg: "http://www.w3.org/2000/svg",
};

const resolver: XPathNSResolver = (prefix: string | null) => {
  if (prefix === null) return null;
  return NAMESPACES[prefix] || null;
};

export const evaluatePath = (contentDocument: Document, path: string) => {
  if (!contentDocument?.documentElement) return null;

  let preparedPath = path.replace(/svg(\[\d+\])?$/, "svg:svg$1");

  let element = contentDocument.evaluate(
    preparedPath,
    contentDocument.documentElement,
    resolver,
    XPathResult.FIRST_ORDERED_NODE_TYPE,
    null
  ).singleNodeValue;

  if (element === null && contentDocument.body) {
    element = contentDocument.body.querySelector(
      `[data-check-access-xpath="${path}"]`
    );
  }

  return element;
};

export const evaluateElementPath = (
  contentDocument: Document,
  path: string
): HTMLElement | null => {
  const node = evaluatePath(contentDocument, path);
  if (!node) return null;

  if (node.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  return node as HTMLElement;
};
