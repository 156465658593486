import { Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box/Box";
import React, { useCallback, useContext, useMemo } from "react";
import {
  getCriteriaState,
  NonCompliantCriteria,
  ReportContext,
  TargetedNonCompliantErrors,
} from "providers/Report";
import { Intervention } from "@ca/report";
import { ReactComponent as TextIcon } from "../../../../assets/icons/icon-text.svg";
import SidebarTopicHeader from "./SidebarTopicHeader";
import CriteriaAccordion from "../accordion/CriteriaAccordion";
import {
  CRITERIA_STATE_DETAILS,
  CriteriaStateTypes,
} from "definitions/criteria_state_details";
import RetributionIcon from "components/check-access/scanReportRetribution/RetributionIcon";
import { InterventionsContext } from "providers/InterventionsProvider";
import useScaner from "hooks/useScaner";
import FormControl from "@mui/material/FormControl/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import FormLabel from "@mui/material/FormLabel/FormLabel";
import Radio from "@mui/material/Radio/Radio";
import { XPathFinderContext } from "providers/XPathFinder";
import { useCurrentProjectPage } from "providers/CurrentFrameProjectPage/CurrentFrameProjectPage";

export interface MandatoryElementsSidebarTopicDetailsProps {}

const MandatoryElementsSidebarTopicDetails: React.FC<
  MandatoryElementsSidebarTopicDetailsProps
> = () => {
  const { pageReport, reportCurrentPage } = useContext(ReportContext)!;
  const currentProjectPage = useCurrentProjectPage();
  const { scrollPictoIntoView } = useContext(XPathFinderContext)!;

  const { palette } = useTheme();
  const { addIntervention, getPageInfos } = useContext(InterventionsContext)!;
  const { currentPageScan } = useScaner();

  const updatePageTitleRelevanceIntervention = useCallback(
    (hasGoodTitle: boolean) => {
      if (!currentPageScan) return;

      addIntervention(
        {
          type: "pageTitleRelevance",
          title: currentPageScan.scan.global.title,
          has_good_title: hasGoodTitle,
        },
        currentPageScan.page_id,
        (it) =>
          it.type === "pageTitleRelevance" &&
          it.title === currentPageScan.scan.global.title
      );

      reportCurrentPage();
    },
    [addIntervention, currentPageScan, reportCurrentPage]
  );

  const renderCriteria = useCallback(
    (title: string, criteriaState: CriteriaStateTypes) => {
      const details = CRITERIA_STATE_DETAILS[criteriaState];
      return (
        <CriteriaAccordion
          title={title}
          Icon={<details.Icon stroke={palette[details.paletteTheme].main} />}
          type={details.paletteTheme}
        />
      );
    },
    [palette]
  );

  const renderNoTargetCriteria = useCallback(
    (title: string, number: number) => {
      const criteria = pageReport?.criterias.find(
        (criteria) =>
          criteria.topic === "mandatoryElements" && criteria.number === number
      );

      if (!criteria) return null;

      return renderCriteria(title, getCriteriaState(criteria));
    },
    [pageReport?.criterias, renderCriteria]
  );

  const titleRelevantCriteria = useMemo(() => {
    if (!currentPageScan) return;

    const criteria = pageReport?.criterias.find(
      (criteria) =>
        criteria.topic === "mandatoryElements" && criteria.number === 6
    );

    if (!criteria) return null;

    const criteriaState = getCriteriaState(criteria);
    const details = CRITERIA_STATE_DETAILS[criteriaState];

    const intervention = getPageInfos(currentProjectPage.id)
      .interventions.filter(
        (intervention): intervention is Intervention.pageTitleRelevance =>
          intervention.type === "pageTitleRelevance"
      )
      .find(
        (intervention) =>
          intervention.title === currentPageScan.scan.global.title
      );

    let interventionFormValue = "";
    if (intervention && intervention.has_good_title) {
      interventionFormValue = "relevant";
    } else if (intervention && !intervention.has_good_title) {
      interventionFormValue = "notRelevant";
    }

    return (
      <CriteriaAccordion
        title={"8.6 - Titre de la page pertinent"}
        Icon={<details.Icon stroke={palette[details.paletteTheme].main} />}
        type={details.paletteTheme}
        defaultIsExpanded={true}
      >
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Le titre de la page est{" "}
          <strong>"{currentPageScan.scan.global.title}"</strong> est-il
          pertinent ?
        </Typography>
        <FormControl>
          <FormLabel>Pertinence</FormLabel>
          <RadioGroup
            row
            name="pageTitleRelevantRadioGroup"
            value={interventionFormValue}
          >
            <FormControlLabel
              value="relevant"
              control={
                <Radio
                  name="pageTitleRelevantRadioGroupRelevant"
                  color={details.paletteTheme}
                  onClick={() => updatePageTitleRelevanceIntervention(true)}
                />
              }
              label="Pertinent"
            />
            <FormControlLabel
              value="notRelevant"
              control={
                <Radio
                  color={details.paletteTheme}
                  name="pageTitleRelevantRadioGroupNotRelevant"
                  onClick={() => updatePageTitleRelevanceIntervention(false)}
                />
              }
              label="Non pertinent"
            />
          </RadioGroup>
        </FormControl>
      </CriteriaAccordion>
    );
  }, [
    currentPageScan,
    currentProjectPage.id,
    getPageInfos,
    pageReport?.criterias,
    palette,
    updatePageTitleRelevanceIntervention,
  ]);

  const renderChangedLanguageCriteria = useCallback(
    (title: string, number: number) => {
      const criteria = pageReport?.criterias.find(
        (criteria) =>
          criteria.topic === "mandatoryElements" && criteria.number === number
      );

      if (!criteria) return null;

      const criteriaState = getCriteriaState(criteria);

      const details = CRITERIA_STATE_DETAILS[criteriaState];

      const errors =
        criteriaState === "nonCompliant"
          ? (criteria as NonCompliantCriteria).state.nonCompliant
          : [];

      const isMissingLangError =
        errors.length === 1 && errors[0].type === "missingDefaultLang";

      return (
        <CriteriaAccordion
          title={title}
          Icon={<details.Icon stroke={palette[details.paletteTheme].main} />}
          type={details.paletteTheme}
        >
          {!isMissingLangError && errors.length > 0 && (
            <Box sx={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
              {errors.map((error, index) => (
                <RetributionIcon
                  onClick={() =>
                    scrollPictoIntoView(
                      "textMandatoryElement",
                      (error as TargetedNonCompliantErrors).x_path
                    )
                  }
                  Icon={(props) => (
                    <TextIcon {...props} fill={palette.common.white} />
                  )}
                  key={index}
                  color={palette[details.paletteTheme].main}
                />
              ))}
            </Box>
          )}
        </CriteriaAccordion>
      );
    },
    [pageReport?.criterias, palette, scrollPictoIntoView]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <SidebarTopicHeader topic="mandatoryElements" titleFontSize="1.5rem" />
      <Box
        sx={{
          margin: "25px 8px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Box>
          {renderNoTargetCriteria("8.1 - Doctype définie", 1)}
          {renderNoTargetCriteria("8.3 - Langue par défaut défini", 3)}
          {renderNoTargetCriteria("8.4 - Langue par défaut pertinente", 4)}
          {renderNoTargetCriteria("8.5 - Titre de la page défini", 5)}
          {titleRelevantCriteria}
          {renderChangedLanguageCriteria(
            "8.7 - Changement de langue indiqué",
            7
          )}
          {renderChangedLanguageCriteria(
            "8.8 - Changement de langue pertinent",
            8
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MandatoryElementsSidebarTopicDetails;
