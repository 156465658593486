import useCurrentProject from "hooks/useCurrentProject";
import { Project } from "hooks/useProject";
import { OrganizationsContext } from "providers/OrganizationsProvider";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Tables } from "supabaseClient";
import { logError } from "utils/SentryUtils";

const checkAccessApiUrl = new URL(process.env.REACT_APP_CHECK_ACCESS_API_URL!);
const checkAccessProxyUrl = new URL(
  process.env.REACT_APP_CHECK_ACCESS_PROXY_URL!
);

export type ProjectPage = Tables<"project_pages">;

export const constructFullUrl = (
  path: string,
  project: Project
): URL | null => {
  try {
    return new URL(`${project.http_scheme}://${project.domain}${path}`);
  } catch (error) {
    logError("Failed to construct full URL", { path, project, error });
    return null;
  }
};

const parseUrl = (url: string | URL, currentProject: Project): URL | null => {
  if (url && typeof url === "string") {
    try {
      return new URL(url);
    } catch {
      return constructFullUrl(url, currentProject);
    }
  } else if (url && typeof url === "object") {
    return url;
  }

  return null;
};

const usePageSwitcher = () => {
  const currentProject = useCurrentProject();
  const { insertProjectPage } = useContext(OrganizationsContext)!;

  const [currentProjectPage, setCurrentProjectPage] = useState<ProjectPage>(
    () => currentProject.pages.find((page) => page.path === "/")!
  );

  const switchOnPageInsertedFlag = useRef<string | null>(null);

  const insertNewProjectPage = useCallback(
    async (url: URL) => {
      const newProjectPageName = url.pathname
        .split("/")
        .filter(Boolean)
        .map((segment) => segment.charAt(0).toUpperCase() + segment.slice(1))
        .join(" ");

      switchOnPageInsertedFlag.current = url.pathname;

      await insertProjectPage({
        project_id: currentProjectPage.project_id,
        path: url.pathname,
        name: newProjectPageName,
        infos: { interventions: [] },
      });
    },
    [currentProjectPage.project_id, insertProjectPage]
  );

  useEffect(() => {
    if (switchOnPageInsertedFlag.current === null) return;

    const pageToSwitch = currentProject.pages.find(
      (page) => page.path === switchOnPageInsertedFlag.current
    );
    if (!pageToSwitch) return;

    switchOnPageInsertedFlag.current = null;

    console.log("Switching page after insert: ", pageToSwitch);
    setCurrentProjectPage(pageToSwitch);
  }, [currentProject.pages]);

  const updateCurrentPage = useCallback(
    async (url: string | URL) => {
      let parsedUrl = parseUrl(url, currentProject);

      console.log("Parsed URL:", parsedUrl);
      console.log("currentDomain:", currentProject.domain);

      if (!parsedUrl) return;
      if (
        parsedUrl.hostname !== currentProject.domain &&
        parsedUrl.origin !== checkAccessApiUrl.origin &&
        parsedUrl.origin !== checkAccessProxyUrl.origin
      ) {
        console.log("Canceled updateCurrentPage, different domain");
        return;
      }

      const newProjectPage = currentProject.pages.find(
        (page) => page.path === parsedUrl?.pathname
      );

      if (newProjectPage && newProjectPage.id !== currentProjectPage.id) {
        console.log("Project page found:", newProjectPage);
        setCurrentProjectPage(newProjectPage);
        return;
      } else if (newProjectPage) {
        console.log("The same page is already loaded:", newProjectPage);
        return;
      }

      await insertNewProjectPage(parsedUrl);
    },
    [currentProject, currentProjectPage.id, insertNewProjectPage]
  );

  const updateCurrentPageRef = useRef(updateCurrentPage);

  useEffect(() => {
    updateCurrentPageRef.current = updateCurrentPage;
  }, [updateCurrentPage]);

  return {
    updateCurrentPageRef,
    currentProjectPage,
  };
};

export default usePageSwitcher;
