import Box from "@mui/material/Box/Box";
import Popper, { PopperProps } from "@mui/material/Popper/Popper";
import Typography from "@mui/material/Typography/Typography";
import { CheckAccessFrameContext } from "providers/CheckAccessFrame/CheckAccessFrame";
import React, { useContext } from "react";

interface Size {
  width: number;
  height: number;
}

export type RetributionNodeOverlayProps = {
  anchorEl: HTMLElement;
  color: string;

  title?: string;
  padding?: Size;
  borderSize?: number;

  onClick?: React.MouseEventHandler;
  retrievalId?: string;
} & (
  | { topEndSlot: React.ReactElement; topEndSlotColor: string }
  | { topEndSlot?: undefined; topEndSlotColor?: undefined }
);

const RetributionNodeOverlay: React.FC<RetributionNodeOverlayProps> = ({
  color,
  anchorEl,
  title,

  borderSize = 3,
  padding = { width: 0, height: 0 },

  onClick,
  retrievalId,

  topEndSlot,
  topEndSlotColor,
}) => {
  const { shadowContainer } = useContext(CheckAccessFrameContext)!;
  const rect = anchorEl.getBoundingClientRect();

  const popperProps: PopperProps = {
    open: true,
    anchorEl,
    onClick,
    container: () => shadowContainer.current,
    style: {
      // pointerEvents in sx prop produces no style, so it is moved to the style prop
      pointerEvents: onClick ? "auto" : "none",
      cursor: onClick ? "pointer" : "default",
      zIndex: 100000,
    },
  };

  return (
    <React.Fragment>
      <Popper
        {...popperProps}
        data-ca-retrival-id={retrievalId}
        modifiers={[
          { name: "flip", enabled: false },
          {
            name: "offset",
            options: {
              offset: [0, -rect.height - borderSize - padding.height / 2],
            },
          },
        ]}
      >
        <Box
          style={{
            width: rect.width + padding.width,
            height: rect.height + padding.height,
            border: `${borderSize}px solid ${color}`,
            backgroundColor: `rgba(${color}, 0.1)`,
            overflow: "hidden",
            borderRadius: "4px",
          }}
        >
          <Box
            style={{
              backgroundColor: color,
              opacity: 0.2,
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
      </Popper>

      {title && (
        <Popper
          {...popperProps}
          placement="top-start"
          modifiers={[
            { name: "flip", enabled: true },
            {
              name: "computeStyles",
              options: { adaptive: false },
            },
            {
              name: "offset",
              options: {
                offset: [-padding.width / 2 - borderSize, padding.height / 2],
              },
            },
          ]}
        >
          <Typography
            color="white"
            sx={{
              pointerEvents: "none",
              backgroundColor: color,
              padding: "4px 12px 2px 12px",
              borderRadius: "4px",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            {title}
          </Typography>
        </Popper>
      )}

      {topEndSlot && (
        <Popper
          {...popperProps}
          placement="top-end"
          onClick={undefined}
          style={{ zIndex: 99999 }}
          modifiers={[
            { name: "flip", enabled: true },
            {
              name: "computeStyles",
              options: { adaptive: false },
            },
            {
              name: "offset",
              options: {
                offset: [padding.width / 2 + borderSize, padding.height / 2],
              },
            },
          ]}
        >
          <div
            style={{
              backgroundColor: topEndSlotColor,
              padding: "4px 12px 2px 12px",
              borderRadius: "4px",
            }}
          >
            {topEndSlot}
          </div>
        </Popper>
      )}
    </React.Fragment>
  );
};

export default RetributionNodeOverlay;
