import React, { useContext, useMemo } from "react";
import Box from "@mui/material/Box/Box";
import useScaner from "hooks/useScaner";
import Typography from "@mui/material/Typography/Typography";
import Switch from "@mui/material/Switch/Switch";
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import IconButton from "@mui/material/IconButton/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import Alert from "@mui/material/Alert/Alert";
import ListItemButton from "@mui/material/ListItemButton/ListItemButton";
import { XPathFinderContext } from "providers/XPathFinder";
import { useHeadingsRetribution } from "../HeadingsRetributionProvider";

const MissingTitlesSidebarRetribution: React.FC = () => {
  const {
    isMissingTitlesEditMode,
    setIsMissingTitlesEditMode,
    setMissingTitleIntervention,
    missingTitlesInterventions,
  } = useHeadingsRetribution();

  const { currentPageScan } = useScaner();
  const { scrollPictoIntoView } = useContext(XPathFinderContext)!;

  const missingsTitles = useMemo(() => {
    if (!currentPageScan) return [];

    return Array.from(missingTitlesInterventions)
      .map((xPath) =>
        currentPageScan.scan.texts.textScans.find(
          (scan) => scan.xPath === xPath
        )
      )
      .filter((e) => e !== undefined);
  }, [currentPageScan, missingTitlesInterventions]);

  return (
    <Box
      sx={{
        marginBottom: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography>Sélection des titres manquants</Typography>
        <Switch
          color="warning"
          checked={isMissingTitlesEditMode}
          onChange={() => setIsMissingTitlesEditMode((prev) => !prev)}
          sx={{
            "& .MuiSwitch-track": {
              backgroundColor: "black",
            },
          }}
        />
      </Box>

      {missingsTitles.length > 0 && (
        <List
          dense={true}
          sx={{
            maxHeight: 200,
            overflow: "auto",
          }}
        >
          {missingsTitles.map((textScan, i) => {
            return (
              <ListItemButton
                key={i}
                sx={{
                  padding: 0,
                  marginY: "2px",
                  width: "100%",
                }}
                onClick={() =>
                  scrollPictoIntoView("missingTitleOverlay", textScan.xPath)
                }
              >
                <Alert
                  severity="error"
                  sx={{
                    width: "100%",
                    padding: "0px 0px 0px 8px",
                    alignItems: "center",
                    "& .MuiAlert-message": {
                      flex: 1,
                    },
                  }}
                >
                  <ListItem
                    sx={{
                      paddingY: 0,
                      paddingRight: "36px",
                      paddingLeft: "0px",
                    }}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        style={{ color: "inherit" }}
                        onClick={(e) => {
                          setMissingTitleIntervention(textScan.xPath, false);
                          e.stopPropagation();
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      primary={textScan.content}
                      slotProps={{
                        primary: {
                          sx: {
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          },
                        },
                      }}
                    />
                  </ListItem>
                </Alert>
              </ListItemButton>
            );
          })}
        </List>
      )}
    </Box>
  );
};

export default MissingTitlesSidebarRetribution;
